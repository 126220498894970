import styled from 'styled-components';

export const BannerContainer = styled.div`
  background: linear-gradient(
    90deg,
    rgb(7, 93, 204) 0%,
    rgb(4, 73, 163) 45%,
    rgb(0, 46, 106) 100%
  );
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
`;

export const ImageContainerBanner = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const CardContainer = styled.div`
  border-radius: 15px;
  border: 1px solid rgba(0, 55, 112, 0.24);
  background: white;
  color: black;
`;

export const ChartContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  height: 49%;
  width: 100%;
`;

export const AgentMonitorContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem 0;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
`;

export const AgentStatistic = styled.div`
  background-color: white;
  width: 100%;
  color: black;
  border: 1px solid rgba(0, 55, 112, 0.24);
  border-radius: 15px;
  height: 100%;
`;

export const AgentCount = styled.div`
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 15px 15px 0 0;
  background: #f2fcff;
  color: #065dcc;
  height: 92px;
  font-family: Montserrat;
`;

export const AgentDetail = styled.div`
  padding: 1.5rem;
  color: black;
`;

export const AgentRankCardContainer = styled.div`
  height: 100%;
  background-color: white;
  width: 100%;
  color: black;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
`;

export const TitleAgentRankCard = styled.h5`
  padding: 1.5rem;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  color: #003770;
  font-family: Montserrat;
`;

export const AgentImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

export const HighlightedSpan = styled.span`
  color: #000000;
  font-weight: 700;
`;
