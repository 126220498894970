import Chart from './Chart';
import ChartDuration from './ChartDuration';
import dayjs from 'dayjs';
import AgentMonitor from './AgentMonitor';
import TopicSentimentMonitor from './TopicSentimentMonitor';
import CustomDateRangePicker from '../../../Components/CustomDateRangePicker/CustomDateRangePicker';
import { Box, Grid, Typography } from '@mui/material';
import { useState, useContext } from 'react';
import { AuthContext } from '@/Components/Authentication/Authentication';
import {
  StatisticCardDuration,
  StatisticCardTranscription,
} from './StatisticCardContainer';
import { DateRangeInterface } from '../../../react-app-env';
import { getDate, saveDate } from '../../../Session/Session';

export interface RangeState {
  startDate: string;
  endDate: string;
}

const ConversationMonitor = () => {
  const authentication = useContext(AuthContext);
  const [range, setRange] = useState<RangeState>(getDate());

  const setStartEndDate = (range: DateRangeInterface) => {
    setRange((c) => ({
      ...c,
      startDate: dayjs(range.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(range.endDate).format('YYYY-MM-DD'),
    }));
    saveDate({
      startDate: dayjs(range.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(range.endDate).format('YYYY-MM-DD'),
    });
  };

  return (
    <>
      <Box>
        <Box display='flex' justifyContent='flex-end'>
          <Box>
            <CustomDateRangePicker
              right={true}
              display='inline'
              startDate=''
              endDate=''
              setStartEndDate={setStartEndDate}
            />
          </Box>
        </Box>

        <Box mt={2}>
          <Typography
            variant='h6'
            color='primary'
            sx={{ fontFamily: 'Montserrat', fontWeight: 700 }}
          >
            Calls & Transcription Statistics
          </Typography>
          <hr style={{ margin: '0.5rem 0 1.5rem' }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <StatisticCardDuration range={range} />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Box
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                flexDirection='column'
              >
                <ChartDuration range={range} />
              </Box>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <StatisticCardTranscription range={range} />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Box
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                flexDirection='column'
              >
                <Chart range={range} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {authentication.auth.data.permissions.includes('statistics.agents') &&
      authentication.auth.data.permissions.includes('statistics.customers') &&
      authentication.auth.data.permissions.includes('features.sentiments') ? (
        <TopicSentimentMonitor range={range} />
      ) : (
        ''
      )}

      {authentication.auth.data.permissions.includes('statistics.agents') ? (
        <AgentMonitor range={range} />
      ) : (
        ''
      )}
    </>
  );
};

export default ConversationMonitor;
