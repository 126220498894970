import { Status } from '../Model/Conversation';
import type { StackedBarItemProps } from '../Components/StackedBar/StackedBar';

const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export interface HMSObjectInterface {
  hour: number;
  minute: number;
  second: number;
}

export const getStringDate = (date: Date) => {
  const mm = date.getMonth();
  const fullMonth = month[mm];
  const dd = date.getDate();
  return [(dd > 9 ? '' : '0') + dd, fullMonth, date.getFullYear()].join(' ');
};

export const getMonthAndDateString = (date: Date) => {
  const mm = date.getMonth();
  const fullMonth = month[mm];
  const dd = date.getDate();
  return `${fullMonth} ${dd}`;
};

export const convertDateToHMSObject = (date: Date): HMSObjectInterface => {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return { hour, minute, second };
};

export const convertHMS = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  let hoursString: string = hours.toString();
  let minutesString: string = minutes.toString();
  let secondsString: string = seconds.toString();
  if (hours !== 0) {
    hoursString = hours + 'h';
  } else {
    hoursString = '';
  }
  if (minutes !== 0) {
    minutesString = minutes + 'm';
  } else {
    minutesString = '';
  }
  if (seconds !== 0) {
    secondsString = seconds + 's';
  } else {
    secondsString = '';
  }
  if (sec === 0) return '0s';
  return `${hoursString}${
    hoursString !== '' && (minutesString !== '' || secondsString !== '')
      ? ' '
      : ''
  }${minutesString}${
    minutesString !== '' && secondsString !== '' ? ' ' : ''
  }${secondsString}`;
};

export const convertHMSObject = (sec: number): HMSObjectInterface => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  return {
    hour: hours,
    minute: minutes,
    second: seconds,
  };
};

export const convertHMSColon = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  let hoursString: string = hours.toString();
  let minutesString: string = minutes.toString();
  let secondsString: string = seconds.toString();
  if (hours !== 0) {
    if (hours < 10) {
      hoursString = '0' + hoursString;
    }
  } else {
    hoursString = '00';
  }
  if (minutes !== 0) {
    if (minutes < 10) {
      minutesString = '0' + minutesString;
    }
  } else {
    minutesString = '00';
  }
  if (seconds !== 0) {
    if (seconds < 10) {
      secondsString = '0' + secondsString;
    }
  } else {
    secondsString = '00';
  }
  if (sec === 0) return '00:00:00';
  return `${hoursString}:${minutesString}:${secondsString}`;
};

export const convertHMSPlayer = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  let hoursString: string = hours.toString();
  let minutesString: string = minutes.toString();
  let secondsString: string = seconds.toString();
  if (hours === 0) {
    hoursString = '';
  }
  if (minutes !== 0) {
    if (minutes < 10) {
      minutesString = '0' + minutesString;
    }
  } else {
    minutesString = '00';
  }
  if (seconds !== 0) {
    if (seconds < 10) {
      secondsString = '0' + secondsString;
    }
  } else {
    secondsString = '00';
  }
  if (sec === 0) return '00:00';
  return `${hoursString}${
    hoursString !== '' ? ':' : ''
  }${minutesString}:${secondsString}`;
};

export const translateIntent = (label: string): string => {
  if (label === 'GOP') {
    return 'Opening';
  } else if (label === 'AIN') {
    return 'Ask Information';
  } else if (label === 'CON') {
    return 'Confirmation';
  } else if (label === 'CHK') {
    return 'History Checking';
  } else if (label === 'INF') {
    return 'Information';
  } else if (label === 'SOL') {
    return 'Solution';
  } else if (label === 'ESC') {
    return 'Escalation';
  } else if (label === 'RES') {
    return 'Response';
  } else if (label === 'EMP') {
    return 'Empathy';
  } else if (label === 'GCL') {
    return 'Closing';
  }
  return '';
};

export const capitalizeWord = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1, word.length);
};

export const snakecaseToWords = (text: string): string => {
  const arrayOfText = text.split('_');
  if (arrayOfText.length === 1) return capitalizeWord(text);
  return arrayOfText
    .map((item) => {
      if (item === 'id') return 'ID';
      return capitalizeWord(item);
    })
    .join(' ');
};

export const validatePassword = (
  password: string,
  confirmPassword: string
): string => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).+$/;
  if (password === '') {
    return 'Please fill password';
  } else if (confirmPassword === '') {
    return 'Please fill confirm password';
  } else if (confirmPassword !== '' && password !== confirmPassword) {
    return 'Passwords do not match';
  } else if (password !== confirmPassword) {
    return 'Passwords do not match';
  } else if (!pattern.test(password)) {
    return 'Please choose a password that contains at least one upper case, lower case, number, and special character';
  } else if (password.length < 8 || password.length > 20) {
    return 'Password length must be 8 to 20 characters';
  }
  return '';
};

export const getStatusTranscription = (object: Status): string => {
  const listStatus = ['failed', 'not_started', 'started', 'finished'];
  let result = '';
  const BreakException: any = {};
  if (object.transcription !== 'finished') {
    result = object.transcription;
  } else {
    try {
      listStatus.forEach((element) => {
        if (object.transcription === element) {
          result = element;
          throw BreakException;
        }
        if (object.ca_scoring === element) {
          result = element;
          throw BreakException;
        }
        if (object.rude_words === element) {
          result = element;
          throw BreakException;
        }
        if (object.sentiments === element) {
          result = element;
          throw BreakException;
        }
      });
    } catch (error) {
      if (error !== BreakException) throw error;
    }
  }
  return result;
};

export const validateFile = (file: File): boolean => {
  if (file === undefined) return false;
  if (file === null) return false;
  const { type } = file;
  if (
    type !== 'audio/wav' &&
    type !== 'audio/mp3' &&
    type !== 'audio/mpeg' &&
    type !== 'audio/gsm' &&
    type !== 'audio/x-gsm'
  ) {
    let ext = '';
    try {
      ext = file.name.split('.')[file.name.split('.').length - 1];
    } catch (error) {
      return false;
    }
    if (ext !== 'gsm') {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const validateAllForm = (object: any, errorHandler: any): boolean => {
  let flag = true;
  Object.entries(object).forEach((data) => {
    if (flag === true) {
      if (data[1] === '' || data[1] === null) {
        errorHandler(`Fill ${data[0]}`);
        flag = false;
      }
    }
  });
  return flag;
};

export const validateMandatoryForm = (
  object: any,
  mandatoryFields: string[],
  errorHandler: any
): boolean => {
  let flag = true;
  mandatoryFields.forEach((key) => {
    if (flag) {
      if (!object[key]) {
        errorHandler(`Please fill ${snakecaseToWords(key)} field`);
        flag = false;
      }
    }
  });
  return flag;
};

export const arrayToFilterString = (
  array: any[],
  identifier: string,
  key: string
): string => {
  if (array.length === 0) return '';
  let tempString = `&${identifier} in (`;
  array.forEach((element: any, index) => {
    if (index === array.length - 1) {
      tempString = tempString + `'${element[key]}'`;
    } else {
      tempString = tempString + `'${element[key]}', `;
    }
  });
  tempString = tempString + `)`;
  return tempString;
};

export const arrayToFilterStringInBetween = (
  from: string | number,
  to: string | number,
  key: string
): string => {
  return `${key} >= ${from}&${key} <= ${to}`;
};

export const arrayToFilterStringBoolean = (
  array: any[],
  identifier: string,
  key: string
): string => {
  if (array.length === 2) return '';
  let string = '';
  array.forEach((element: any) => {
    if (element[key] === true) {
      string = `&${identifier} = true`;
    } else {
      string = `&${identifier} = false`;
    }
  });
  return string;
};

export const copyObject = (obj: any) => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj));
};

export const sentimentColor = {
  negative: '#FB4848',
  positive: '#3FE4BC',
  neutral: '#006EFE',
};

export interface SentimentProps extends StackedBarItemProps {
  marker: string[] | undefined;
}

export const generateSentiments = (
  positiveValue: number,
  neutralValue: number,
  negativeValue: number,
  positiveMarker: string[] | undefined,
  neutralMarker: string[] | undefined,
  negativeMarker: string[] | undefined
): SentimentProps[] => [
  {
    title: 'Positive',
    color: sentimentColor.positive,
    value: positiveValue,
    marker: positiveMarker,
  },
  {
    title: 'Neutral',
    color: sentimentColor.neutral,
    value: neutralValue,
    marker: neutralMarker,
  },
  {
    title: 'Negative',
    color: sentimentColor.negative,
    value: negativeValue,
    marker: negativeMarker,
  },
];
