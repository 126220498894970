import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { getUserImage, getUserData } from '../../Model/User';
import { toastConfig } from '../../Components/Style/Style';

import type { AuthenticationState } from '@/Components/Authentication/Authentication';

const usePersonalData = (authentication: AuthenticationState) => {
  const { data: image } = useQuery(
    'fetchImage',
    async () => {
      const file = await getUserImage(authentication.auth.data.token);
      if (file) return URL.createObjectURL(file);
      return undefined;
    },
    {
      onError: (error) => {
        if (error instanceof Error) {
          toast.error(error.message, toastConfig);
        } else {
          toast.error('Unknown error', toastConfig);
        }
      },
    }
  );

  const { data: user } = useQuery(
    'fetchUserData',
    () => getUserData(authentication.auth.data.token),
    {
      onError: (error) => {
        if (error instanceof Error) {
          toast.error(error.message, toastConfig);
        } else {
          toast.error('Unknown error', toastConfig);
        }
      },
    }
  );

  return {
    image,
    user,
  };
};

export default usePersonalData;
