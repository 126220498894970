import { AuthContext } from '@/Components/Authentication/Authentication';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedWrapperProps {
  children?: React.ReactNode;
}

const ProtectedWrapper: React.FC<ProtectedWrapperProps> = ({ children }) => {
  const authentication = useContext(AuthContext);

  if (authentication.auth.isAuth) return <>{children}</>;

  return <Navigate to='/' replace />;
};

export default ProtectedWrapper;
