import { Typography } from '@mui/material';
const Footer = () => {
  return (
    <>
      <Typography align='center' variant='caption' display='block' mt={2}>
        Coala v1.0.0 © 2022 PT Prosa Solusi Cerdas.
      </Typography>
      <Typography align='center' variant='caption' display='block'>
        All rights reserved
      </Typography>
    </>
  );
};

export default Footer;
