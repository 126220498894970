import ProfilePopper from './TopBarComponents/ProfilePopper';
import Logo from '../../Assets/logo-dark.svg';

import { Box, IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

interface TopBarProps {
  onToggleDrawer: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ onToggleDrawer }) => {
  return (
    <Box
      sx={{
        height: 50,
        width: '100%',
        background: 'white',
        boxShadow: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 10,
        position: 'fixed',
        top: 0,
      }}
    >
      <Box display='flex' alignItems='center'>
        <Box width='70px' display='flex' justifyContent='center'>
          <IconButton color='inherit' onClick={onToggleDrawer} id='nav-drawer'>
            <MenuIcon />
          </IconButton>
        </Box>
        <img src={Logo} alt='logo-coala' style={{ height: '42px' }} />
      </Box>
      <Box sx={{ pr: 2 }}>
        <ProfilePopper />
      </Box>
    </Box>
  );
};

export default TopBar;
