import React, { Suspense } from 'react';

import Login from '@/Pages/Login/Login';
import CallMonitor from '@/Pages/CallMonitor/CallMonitor';
import Wrapper from '@/Components/Wrapper/Wrapper';
import NotFound from '@/Pages/NotFound/NotFound';
import Authentication from '@/Components/Authentication/Authentication';
import Loading from '@/Components/Loading/Loading';
import ProtectedWrapper from '@/Components/ProtectedWrapper';
import ScrollToTopCustom from './Components/ScrollToTop/ScrollToTopCustom';

import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ContainerWrapper, theme } from '@/Components/Style/Style';
import TopicSentimentDetail from './Pages/TopicSentimentDetail/TopicSentimentDetail';

const Agent = React.lazy(() => import('@/Pages/Agent/Agent'));
const AgentDetail = React.lazy(() => import('@/Pages/AgentDetail/AgentDetail'));
const AuditTrail = React.lazy(
  () => import('@/Pages/AuditTrailLog/AuditTrailLog')
);
const TopicAdministration = React.lazy(
  () => import('@/Pages/TopicAdministration/TopicAdministration')
);
const ConversationList = React.lazy(
  () => import('@/Pages/Conversation/Component/ConversationList')
);
const FormConversation = React.lazy(
  () => import('@/Pages/Conversation/Component/FormConversation')
);
const Transcription = React.lazy(
  () => import('@/Pages/Transcription/Transcription')
);
const Administration = React.lazy(
  () => import('@/Pages/Administration/Administration')
);
const FormSop = React.lazy(() => import('@/Pages/SopRule/Component/FormSop'));
const Profile = React.lazy(() => import('@/Pages/Profile/Profile'));
const SopRule = React.lazy(() => import('@/Pages/SopRule/SopRule'));
const TopicSentiment = React.lazy(
  () => import('@/Pages/TopicSentiment/TopicSentiment')
);

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface PublicRouteInterface {
  id: number;
  path: string;
  component: JSX.Element;
}

const PublicRoute: PublicRouteInterface[] = [
  {
    id: 1,
    path: '/',
    component: <Login />,
  },
  {
    id: 999,
    path: '*',
    component: <NotFound />,
  },
];

const ProtectedRouteData: PublicRouteInterface[] = [
  {
    id: 2,
    path: '/call-monitor',
    component: <CallMonitor />,
  },
  {
    id: 3,
    path: '/conversation',
    component: <ConversationList />,
  },
  {
    id: 4,
    path: '/conversation/form',
    component: <FormConversation />,
  },
  {
    id: 5,
    path: '/transcription/:id',
    component: <Transcription />,
  },
  {
    id: 6,
    path: '/agent',
    component: <Agent />,
  },
  {
    id: 7,
    path: '/agent/detail',
    component: <AgentDetail />,
  },
  {
    id: 8,
    path: '/administration/:section',
    component: <Administration />,
  },
  {
    id: 9,
    path: '/audit-trail-logs',
    component: <AuditTrail />,
  },
  {
    id: 10,
    path: '/conversation/form/:id',
    component: <FormConversation />,
  },
  {
    id: 19,
    path: '/agent/history/:id',
    component: <AgentDetail />,
  },
  {
    id: 20,
    path: '/sop/form',
    component: <FormSop />,
  },
  {
    id: 21,
    path: '/sop/form/:id',
    component: <FormSop />,
  },
  {
    id: 22,
    path: '/setting/:section',
    component: <Profile />,
  },
  {
    id: 23,
    path: '/topic-administration',
    component: <TopicAdministration />,
  },
  {
    id: 24,
    path: 'topic-sentiment',
    component: <TopicSentiment />,
  },
  {
    id: 25,
    path: 'sop',
    component: <SopRule />,
  },
  {
    id: 26,
    path: '/topic-sentiment/:topic',
    component: <TopicSentimentDetail />,
  },
];

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <Authentication>
                <Wrapper />
                <ScrollToTopCustom />
                <Suspense fallback={<Loading />}>
                  <Routes>
                    {ProtectedRouteData.map((data, index) => {
                      return (
                        <Route
                          key={index}
                          path={data.path}
                          element={
                            <ProtectedWrapper>
                              <ContainerWrapper>
                                {data.component}
                              </ContainerWrapper>
                            </ProtectedWrapper>
                          }
                        ></Route>
                      );
                    })}

                    {PublicRoute.map((data, index) => {
                      return (
                        <Route
                          key={index}
                          path={data.path}
                          element={data.component}
                        ></Route>
                      );
                    })}
                  </Routes>
                </Suspense>
              </Authentication>
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
