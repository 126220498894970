import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { generalErrorHandler } from './ErrorHandler';

export interface DialogInterface {
  id: number;
  speaker: string;
  text: string;
  start: number;
  end: number;
  ext: {
    intent?: null | string;
    rude_words?: string[];
    sentiment?: string;
    concepts?: Record<string, Record<string, string>>;
  };
}

interface ConceptInterface {
  concept: string;
  positive: number;
  neutral: number;
  negative: number;
  total: number;
  markers?: {
    positive?: string[];
    negative?: string[];
    neutral?: string[];
  };
  positive_part: number;
  neutral_part: number;
  negative_part: number;
}

interface ConversationSopRule {
  type: string;
  score: number;
  intent: string;
}

export interface ConversationSop {
  name: string;
  rules: ConversationSopRule[];
  score_threshold: number;
  intents_sequence: string[];
}

type IntentionLabels =
  | 'GOP'
  | 'AIN'
  | 'CON'
  | 'CHK'
  | 'INF'
  | 'SOL'
  | 'ESC'
  | 'RES'
  | 'EMP'
  | 'GCL'
  | 'COM';

export interface TranscriptionInterface {
  conversation_id: number;
  type: string;
  status: {
    transcription: string;
    ca_scoring?: string;
    rude_words?: string;
  };
  start_time: string;
  end_time: string;
  model: string;
  features: string[];
  ext: {
    ca_scoring?: {
      score: null | number;
      reference: string;
      reference_id: number;
      score_details: Record<IntentionLabels, number> | null;
      sop?: ConversationSop;
    };
    sentiments?: {
      positive: null | number;
      neutral: null | number;
      negative: null | number;
      total: null | number;
      positive_part: null | number;
      neutral_part: null | number;
      negative_part: null | number;
      overall: string;
    };
    concepts?: ConceptInterface[];
  };
  dialog: DialogInterface[];
}

export const DummyTranscription: TranscriptionInterface = {
  conversation_id: -1,
  type: 'automatic',
  status: {
    transcription: 'finished',
    ca_scoring: 'finished',
    rude_words: 'finished',
  },
  start_time: '2020-12-03T06:20:11.515145',
  end_time: '2020-12-03T06:20:11.515145',
  model: 'bca',
  features: [],
  ext: {
    ca_scoring: {
      score: 0,
      reference: '',
      reference_id: -1,
      score_details: null,
    },
  },
  dialog: [
    {
      id: -999,
      speaker: '',
      text: '',
      start: 2,
      end: 3,
      ext: {
        intent: 'RES',
        rude_words: ['test'],
      },
    },
  ],
};

export const getTranscriptionConversation = async (
  id: string,
  token: string
) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/conversations/${id}/transcription`,
      config
    );
    const data: TranscriptionInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getTranscriptionStatistics = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/conversations/detail`,
      config
    );
    const data: any = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getTranscriptionSummary = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/conversations/transcriptions`,
      config
    );
    const data: any = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getTranscriptionStatus = async (id: string, token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/conversations/${id}/transcription/status`,
      config
    );
    const data: any = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};
