import styled from 'styled-components';

export const SignIn = styled.div`
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #6c757d;
  text-align: center;
  font-family: Montserrat;
  font-weight: 600;
`;
