import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';

import '@fontsource/roboto';
import '@fontsource/poppins';
import '@fontsource/montserrat';
import '@fontsource/open-sans';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './index.css';

const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);
root.render(
  <>
    <ToastContainer />
    <App />
  </>
);

serviceWorkerRegistration.register();

reportWebVitals();
