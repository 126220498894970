import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { ToastOptions } from 'react-toastify';
import { createTheme } from '@mui/material/styles';
import { Button, TextField, Theme, Tooltip } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

// export const PrimaryColor = '#02587b';
export const PrimaryColor = '#003770';
export const PrimaryColorDark = '#023047';

export const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: PrimaryColor,
      contrastText: '#fff',
    },
  },
  spacing: 8,
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginRight: theme.spacing(1),
      minWidth: 120,
      backgroundColor: PrimaryColor,
      color: '#fff',
    },
    icon: {
      fill: '#333333',
    },
    formControlHighlight: {
      minWidth: 180,
      background: '#ffffff',
      color: '#4d4d4d',
      border: '1px solid #2146BF',
      borderRadius: '8px',
    },
  })
);

export const SearchButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#cecece',
    },
  },
}))(Button);

export const SearchField = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
  },
}))(TextField);

export const TitlePrimary = styled.div`
  background-color: ${PrimaryColor};
  color: white;
  padding: 1.5rem;
  font-size: large;
`;

export const toastConfig: ToastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const CustomHR = styled.hr`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const ContainerWrapper = styled.div`
  margin-left: 5.7rem;
  margin-top: calc(50px + 1.5rem);
  margin-right: 1.5rem;
`;

export const RoundedIconContainer = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`;

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'Poppins',
    border: '1px solid #dadde9',
  },
}))(Tooltip);
