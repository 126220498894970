import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
// import Button from '@mui/material';
// import { FileDownload } from '@mui/icons-material';
import ConversationMonitor from './Component/ConversationMonitor';

const CallMonitor = () => {
  const today = new Date();
  // const lastUpdated = new Date('2022-06-06T13:00:00');
  // const lastUpdatedString = dayjs(lastUpdated).format('D MMMM YYYY h:mm A');
  return (
    <div id='call-monitor'>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mb={2}
      >
        <Box>
          <Typography
            variant='h6'
            color='primary'
            style={{ fontFamily: 'Montserrat', fontWeight: 700 }}
          >
            Call Monitor
          </Typography>
          <Typography variant='caption' style={{ fontFamily: 'Poppins' }}>
            {dayjs(today).format('dddd, D MMMM YYYY')}
          </Typography>
        </Box>
        {/* <Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button sx={{ p: 0, fontFamily: 'Poppins', fontWeight: 700 }}>
              <FileDownload />
              Export Data
            </Button>
          </Box>
          <Typography variant='caption' style={{ fontFamily: 'Poppins' }}>
            {`Last updated at ${lastUpdatedString}`}
          </Typography>
        </Box> */}
      </Box>
      <Box
        bgcolor='white'
        boxShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
        borderRadius='4px'
        p={5}
      >
        <ConversationMonitor />
      </Box>
    </div>
  );
};

export default CallMonitor;
