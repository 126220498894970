// import { PrimaryColor } from './../Style/Style';
import styled from 'styled-components';

export const SidebarContent = styled.div`
  color: white;
  text-decoration: none;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: left;
  transition: 0.4s;
  padding-left: 1.1rem;
  position: relative;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: #002047;
  }
`;

export const SidebarContentName = styled.div`
  background-color: transparent;
  width: 250px;
  position: absolute;
  text-align: left;
  transition: visibility 0, ease;
  display: flex;
  align-items: center;
`;

export const HiddenSideBarItem = styled.div`
  display: block;
`;

export const HoverSideBarItem = styled.div`
  &:hover ${HiddenSideBarItem} {
    display: block !important;
    color: darkblue;
  }
`;

export const HiddenBarContainer = styled.div`
  height: 200px;
  transition: height 2s;
`;
