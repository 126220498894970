import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Grid,
  Stack,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { RangeState } from './ConversationMonitor';
import {
  AgentImage,
  AgentRankCardContainer,
  HighlightedSpan,
  TitleAgentRankCard,
} from '../CallMonitorStyle';
import { getCustomerOpinion } from '@/Model/CustomerOpinion';
import { PrimaryColor } from '@/Components/Style/Style';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../Components/Style/Style';

interface CustomerOpinionProps {
  type: string;
  score: number;
  maxValue: number;
}

const CustomerOpinionItem = ({
  type,
  score,
  maxValue,
}: CustomerOpinionProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      p={2}
      sx={{
        transition: '0.3s',
        borderRadius: '0',
      }}
    >
      <Box display='flex' alignItems='center' width='100%'>
        <AgentImage
          src={
            type === 'good'
              ? '/good.svg'
              : type === 'bad'
              ? '/bad.svg'
              : '/neutral.svg'
          }
          alt={`${type}-icon`}
        />
        <Box ml={2} flexGrow={1}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 700,
              color: 'black',
            }}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </Typography>
          <LinearProgress
            variant='determinate'
            value={(score / maxValue) * 100}
            sx={{
              height: 10,
              my: 0.75,
              borderRadius: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                bgcolor: '#e6e6e6',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                bgcolor:
                  type === 'good'
                    ? '#4CCBAD'
                    : type === 'bad'
                    ? '#FB4848'
                    : '#006EFE',
              },
            }}
          />
          <Typography
            variant='caption'
            sx={{ fontFamily: 'Poppins', color: 'black' }}
          >
            Total Opinion <HighlightedSpan>{score}</HighlightedSpan>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface CustomerOpinionCardProps {
  range: RangeState;
  showTotal: boolean;
  opinionData: any;
}

const CustomerOpinionCard = ({
  range,
  showTotal,
  opinionData,
}: CustomerOpinionCardProps) => {
  const authentication = useContext(AuthContext);
  const [data, setData] = useState({ good: 0, bad: 0, neutral: 0, total: 0 });

  useEffect(() => {
    if (opinionData === null) {
      const fetchCustomerOpinion = async () => {
        const result = await getCustomerOpinion(
          authentication.auth.data.token,
          range.startDate,
          range.endDate
        );
        if (result === false) {
          authentication.func.changeAuthToFalse();
        } else if (typeof result === 'string') {
          toast.dark(result, toastConfig);
        } else {
          let good =
            result.customer_opinions_count.good !== undefined
              ? result.customer_opinions_count.good
              : 0;
          let bad =
            result.customer_opinions_count.bad !== undefined
              ? result.customer_opinions_count.bad
              : 0;
          let neutral =
            result.customer_opinions_count.neutral !== undefined
              ? result.customer_opinions_count.neutral
              : 0;
          let total = good + bad + neutral;
          if (total === 0) {
            total = 1;
          }
          setData({ good: good, bad: bad, neutral: neutral, total: total });
        }
      };
      fetchCustomerOpinion();
    } else {
      let good = opinionData.good !== undefined ? opinionData.good : 0;
      let bad = opinionData.bad !== undefined ? opinionData.bad : 0;
      let neutral = opinionData.neutral !== undefined ? opinionData.neutral : 0;
      let total = good + bad + neutral;
      if (total === 0) {
        total = 1;
      }
      setData({ good: good, bad: bad, neutral: neutral, total: total });
    }
  }, [range, authentication, opinionData]);

  return (
    <>
      <AgentRankCardContainer>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <TitleAgentRankCard>Customer Opinion</TitleAgentRankCard>
          {showTotal && (
            <Grid
              container
              direction='row'
              sx={{
                fontFamily: 'Poppins',
                color: '#464646',
                fontWeight: 700,
                fontSize: '16px',
                marginLeft: '1%',
                width: '90%',
                backgroundColor: '#F1F6FF',
                borderRadius: '10px',
                padding: 1.5,
                textAlign: 'center',
                paddingBottom: 2,
              }}
              spacing={1}
              justifyContent='center'
              alignItems='center'
            >
              <Grid
                item
                style={{
                  fontFamily: 'Poppins',
                  color: PrimaryColor,
                  fontWeight: 700,
                  fontSize: '26px',
                }}
              >
                {data.total}
              </Grid>
              <Grid item>Total Opinion</Grid>
            </Grid>
          )}
        </Stack>
        <CustomerOpinionItem
          score={data.good}
          maxValue={data.total}
          type='good'
        />
        <CustomerOpinionItem
          score={data.neutral}
          maxValue={data.total}
          type='neutral'
        />
        <CustomerOpinionItem
          score={data.bad}
          maxValue={data.total}
          type='bad'
        />
      </AgentRankCardContainer>
    </>
  );
};

export default CustomerOpinionCard;
