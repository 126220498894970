import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { generalErrorHandler } from './ErrorHandler';

export interface ConversationDurationInterface {
  count: number;
  average: number;
  shortest: number;
  longest: number;
  total: number;
}

export const getConversationsDuration = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const { data }: { data: ConversationDurationInterface } = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/conversations/durations`,
      config
    );
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export interface ConversationsDurationDetailInterface {
  name: string;
  average: number;
  shortest: number;
  longest: number;
  total: number;
}

export const getConversationsDurationDetail = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const { data }: { data: ConversationsDurationDetailInterface[] } =
      await Axios.get(
        `${process.env.REACT_APP_BACKEND}/statistics/conversations/durations_detail`,
        config
      );
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};
