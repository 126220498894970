import { useContext, useEffect, useState } from 'react';
import { getTranscriptionStatistics } from '../../../Model/Transcription';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { ChartContainer } from '../CallMonitorStyle';
import { RangeState } from './ConversationMonitor';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { PrimaryColor } from '../../../Components/Style/Style';

interface ChartProps {
  range: RangeState;
}

interface CustomAxisTickProps {
  x?: number;
  y?: number;
  payload?: any;
}

interface CustomTooltipProps {
  payload?: any;
  label?: string;
  active?: boolean;
}

export const CustomAxisTick = ({ x, y, payload }: CustomAxisTickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor='start'
        transform='rotate(90)'
        fontFamily='Poppins'
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ payload, label, active }: CustomTooltipProps) => {
  if (active) {
    return (
      <div
        style={{
          borderRadius: '10px',
          background: 'white',
          border: '1px solid rgba(0, 55, 112, 0.24)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px 10px 0 0',
            background: '#f2fcff',
            color: '#065dcc',
            padding: '0.25rem',
            fontFamily: 'Poppins',
          }}
        >
          <h4>{label}</h4>
        </div>
        <div style={{ padding: '0 1rem' }}>
          {payload?.map((item: any, index: any) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: '150px',
                margin: '8px 0',
                fontFamily: 'Poppins',
              }}
              key={`custom-tooltip-chart-${index}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    background: item.fill,
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                {item.name}
              </div>
              <h4 style={{ color: PrimaryColor, fontWeight: 700 }}>
                {item.value}
              </h4>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const Chart = ({ range }: ChartProps) => {
  const authentication = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTranscriptionStatistics = async () => {
      const result = await getTranscriptionStatistics(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else {
        setData(result);
      }
    };
    fetchTranscriptionStatistics();
  }, [range, authentication]);

  return (
    <ChartContainer>
      <div style={{ padding: '0 1.5rem' }}>
        <div style={{ marginBottom: '1.5rem' }}>
          <h3
            style={{
              marginTop: 0,
              fontFamily: 'Montserrat',
              color: PrimaryColor,
            }}
          >
            Transcription Status
          </h3>
        </div>
        <div style={{ height: '390px', marginLeft: '-2rem' }}>
          <ResponsiveContainer width='99%' height='100%'>
            <BarChart
              data={data}
              margin={{
                bottom: 32,
              }}
            >
              <XAxis
                dataKey='name'
                height={120}
                style={{ fontFamily: 'Poppins' }}
                interval={0}
                tick={<CustomAxisTick />}
              />
              <YAxis allowDecimals={false} style={{ fontFamily: 'Poppins' }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                barSize={30}
                dataKey='failed'
                stackId='a'
                fill='#FB4848'
                name='Failed'
              />
              <Bar
                barSize={30}
                dataKey='not_started'
                stackId='a'
                fill='#DCDCDC'
                name='On Queue'
              />
              <Bar
                barSize={30}
                dataKey='started'
                stackId='a'
                fill='#006EFE'
                name='In Progress'
              />
              <Bar
                barSize={30}
                dataKey='finished'
                stackId='a'
                fill='#3FE4BC'
                name='Succeeded'
                // shape={<TriangleBar />}s
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </ChartContainer>
  );
};

export default Chart;
