import { useContext, useEffect, useState } from 'react';
import { AgentCount, AgentDetail, AgentStatistic } from '../CallMonitorStyle';
import { Box, Grid } from '@mui/material';
import { RangeState } from './ConversationMonitor';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { MoonLoader } from 'react-spinners';
import { PrimaryColor, toastConfig } from '../../../Components/Style/Style';
import {
  AgentSummaryInterface,
  getAgentSummaryStatistics,
} from '../../../Model/Agent';
import { toast } from 'react-toastify';

interface AgentStatisticCardProps {
  range: RangeState;
}

const AgentStatisticCard = ({ range }: AgentStatisticCardProps) => {
  const authentication = useContext(AuthContext);
  const [data, setData] = useState<AgentSummaryInterface>({
    rude_words_count: 0,
    average_ca_score: 0,
    ca_below_threshold: 0,
    ca_above_threshold: 0,
    active_agents: -999,
  });

  useEffect(() => {
    const fetchAgentSummary = async () => {
      const result = await getAgentSummaryStatistics(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else if (typeof result === 'string') {
        toast.dark(result, toastConfig);
      } else {
        setData(result);
      }
    };
    fetchAgentSummary();
  }, [range, authentication]);

  return (
    <AgentStatistic id='agent-statistic-card'>
      <AgentCount>
        ACTIVE AGENT
        <br />
        <Box mt={0.75}>
          {data.active_agents === -999 ? (
            <MoonLoader size={20} color='white' />
          ) : (
            data.active_agents
          )}
        </Box>
      </AgentCount>
      <AgentDetail>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          fontFamily='Poppins'
        >
          <Grid item>Average CS Score</Grid>
          <Grid item sx={{ color: PrimaryColor, fontWeight: 700 }}>
            {data.active_agents === -999 ? (
              <MoonLoader size={10} color={PrimaryColor} />
            ) : data.average_ca_score ? (
              data.average_ca_score.toFixed(2)
            ) : (
              '0'
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          mt={2}
          justifyContent='space-between'
          fontFamily='Poppins'
        >
          <Grid item>CS Below Threshold</Grid>
          <Grid item sx={{ color: PrimaryColor, fontWeight: 700 }}>
            {data.active_agents === -999 ? (
              <MoonLoader size={10} color={PrimaryColor} />
            ) : (
              data.ca_below_threshold
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          mt={2}
          justifyContent='space-between'
          fontFamily='Poppins'
        >
          <Grid item>CS Above Threshold</Grid>
          <Grid item sx={{ color: PrimaryColor, fontWeight: 700 }}>
            {data.active_agents === -999 ? (
              <MoonLoader size={10} color={PrimaryColor} />
            ) : (
              data.ca_above_threshold
            )}
          </Grid>
        </Grid>
      </AgentDetail>
    </AgentStatistic>
  );
};

export default AgentStatisticCard;
