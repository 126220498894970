import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { AgentRankingInterface, getAgentPicture } from '../../../Model/Agent';
import { AuthContext } from '@/Components/Authentication/Authentication';
import {
  AgentImage,
  AgentRankCardContainer,
  HighlightedSpan,
  TitleAgentRankCard,
} from '../CallMonitorStyle';
import { copyObject } from '@/Utility/utils';

interface AgentItemProps {
  data: AgentRankingInterface;
  isLow?: boolean;
  isLastItem: boolean;
}

const AgentItem = ({ data, isLow, isLastItem }: AgentItemProps) => {
  const authentication = useContext(AuthContext);
  const [image, setImage] = useState('');

  useEffect(() => {
    const fetchImageData = async () => {
      const result = await getAgentPicture(
        authentication.auth.data.token,
        data.agent_id
      );
      if (result !== false) {
        const objectURL = URL.createObjectURL(result);
        setImage(objectURL);
      }
    };
    if (data.agent_id !== '') {
      fetchImageData();
    }
  }, [data, authentication]);

  return (
    <Box
      display='flex'
      alignItems='center'
      p={2}
      sx={{
        transition: '0.3s',
        borderRadius: isLastItem ? '0 0 10px 10px' : '0',
        ':hover': {
          background: '#eeeeee',
        },
      }}
    >
      <Box display='flex' alignItems='center' width='100%'>
        <AgentImage
          src={image}
          alt={`image-${data.name.replace(/\s+/g, '-').toLocaleLowerCase()}`}
        />
        <Box ml={2} flexGrow={1}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 700,
              color: 'black',
            }}
          >
            {data.name}
          </Typography>
          <LinearProgress
            variant='determinate'
            value={data.score}
            sx={{
              height: 10,
              my: 0.75,
              borderRadius: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                bgcolor: '#e6e6e6',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                bgcolor: isLow ? '#fb4848' : '#4ccbad',
              },
            }}
          />
          <Typography
            variant='caption'
            sx={{ fontFamily: 'Poppins', color: 'black' }}
          >
            Total Calls <HighlightedSpan>{data.calls}</HighlightedSpan>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface AgentRankCardProps {
  data: AgentRankingInterface[];
  title: string;
  isLow?: boolean;
}

const AgentRankCard = ({ data, title, isLow }: AgentRankCardProps) => {
  const realData = data.slice(0, 5);
  return (
    <>
      <AgentRankCardContainer>
        <TitleAgentRankCard>{title}</TitleAgentRankCard>
        {realData.map((element, index) => {
          const item = copyObject(element);
          item.score = element.score > 100 ? 100 : element.score;
          return (
            <a
              key={index}
              href={`/agent/history/${item.agent_id}`}
              style={{ textDecoration: 'none' }}
            >
              <AgentItem
                data={item}
                isLow={isLow}
                isLastItem={index === realData.length - 1}
              />
            </a>
          );
        })}
      </AgentRankCardContainer>
    </>
  );
};

export default AgentRankCard;
