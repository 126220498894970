import { useEffect, useState } from 'react';
import { Grid, Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import { getDate, saveDate } from '../../Session/Session';
import TopicDetailInformation from './Component/TopicDetailInformation';
import TopicList from './Component/TopicList';

export type RangeTypeTopicDetail = {
  startDate: string;
  endDate: string;
};

const TopicSentimentDetail = () => {
  const [range, setRange] = useState<RangeTypeTopicDetail>(getDate());

  useEffect(() => {
    saveDate(range);
  }, [range]);

  return (
    <div id='agent-detail'>
      <Breadcrumbs sx={{ mb: 2 }} separator={<ArrowRight />}>
        <Link href='/call-monitor' sx={{ fontFamily: 'Open Sans' }}>
          Call Monitor
        </Link>
        <Link href='/topic-sentiment' sx={{ fontFamily: 'Open Sans' }}>
          Topic Sentiment
        </Link>
        <Typography sx={{ fontFamily: 'Open Sans' }}>
          Topic Sentiment Detail
        </Typography>
      </Breadcrumbs>
      <Box bgcolor='white' p={3}>
        <Box mb={4}>
          <Typography
            variant='h6'
            color='primary'
            style={{ fontFamily: 'Montserrat', fontWeight: 700 }}
          >
            Detail Topics
          </Typography>
        </Box>
        <Grid spacing={3} container>
          <Grid item xs={12} md={4} xl={3}>
            <TopicDetailInformation range={range} />
          </Grid>
          <Grid item xs={12} md={8} xl={9}>
            <TopicList range={range} setRange={setRange} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default TopicSentimentDetail;
