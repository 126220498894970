import { ArrowUpward } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { PrimaryColor } from '../Style/Style';

const ScrollToTopCustom = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  return (
    <IconButton
      sx={{
        display: scrollPosition > 300 ? 'block' : 'none',
        backgroundColor: PrimaryColor,
        borderRadius: '100%',
        position: 'fixed',
        bottom: '5px',
        right: '5px',
        paddingX: '6px',
        paddingY: '2px',
        '&:hover, &:focus': {
          backgroundColor: PrimaryColor,
        },
        zIndex: 10,
        boxShadow: '0px 0px 8px rgba(255, 255, 255, 0.30)',
      }}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <ArrowUpward
        sx={{ color: 'white', marginTop: '3px' }}
        fontSize='medium'
      />
    </IconButton>
  );
};

export default ScrollToTopCustom;
