import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { RangeState } from './ConversationMonitor';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { ActiveHoursInterface, getActiveHours } from '../../../Model/Agent';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../Components/Style/Style';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
} from 'recharts';
import { ChartContainer } from '../CallMonitorStyle';
import { copyObject } from '../../../Utility/utils';
import { PrimaryColor } from '../../../Components/Style/Style';

const initialChartData = [...Array(24).keys()].map((hour, index) => {
  const current: string = `${hour < 10 ? '0' : ''}${hour}:00`;
  const next =
    index === 23 ? '00:00' : `${hour + 1 < 10 ? '0' : ''}${hour + 1}:00`;
  return {
    agents: 0,
    calls: 0,
    hour: `${current} - ${next}`,
  };
});

interface AgentActiveHoursProps {
  range: RangeState;
}

interface CustomAxisTickProps {
  x?: number;
  y?: number;
  payload?: any;
}

interface CustomTooltipProps {
  payload?: any;
  label?: string;
  active?: boolean;
}

const CustomTooltip = ({ payload, label, active }: CustomTooltipProps) => {
  if (active) {
    return (
      <div
        style={{
          borderRadius: '10px',
          background: 'white',
          border: '1px solid rgba(0, 55, 112, 0.24)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px 10px 0 0',
            background: '#f2fcff',
            color: '#065dcc',
            padding: '0.25rem',
            fontFamily: 'Poppins',
          }}
        >
          <h4>{label}</h4>
        </div>
        <div style={{ padding: '0 1rem' }}>
          {payload?.map((item: any, index: any) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: '150px',
                margin: '8px 0',
                fontFamily: 'Poppins',
              }}
              key={`custom-tooltip-agentactivehours-${index}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    background: item.stroke,
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                Total
              </div>
              <h4 style={{ color: PrimaryColor, fontWeight: 700 }}>
                {`${item.value} Agent${item.value > 1 ? 's' : ''}`}
              </h4>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const CustomAxisTick = ({ x, y, payload }: CustomAxisTickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor='start'
        transform='rotate(90)'
        fontFamily='Poppins'
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomLabel = () => {
  return (
    <g>
      <text
        x={0}
        y={0}
        textAnchor='start'
        transform='rotate(-90), translate(-130, 60)'
        fontFamily='Poppins'
      >
        Agents
      </text>
    </g>
  );
};

const AgentActiveHours = ({ range }: AgentActiveHoursProps) => {
  const authentication = useContext(AuthContext);
  const [data, setData] = useState<ActiveHoursInterface[]>(initialChartData);

  useEffect(() => {
    const fetchActiveHours = async () => {
      const result = await getActiveHours(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else if (typeof result === 'string') {
        toast.dark(result, toastConfig);
      } else {
        const tempResult: ActiveHoursInterface[] = copyObject(initialChartData);
        result.forEach((element) => {
          const elementIndex = tempResult.findIndex(
            (e) => e.hour === element.hour
          );
          if (elementIndex !== -1) tempResult[elementIndex] = element;
        });
        setData(tempResult);
      }
    };
    fetchActiveHours();
  }, [range, authentication]);

  return (
    <Box border='1px solid #D7D7D7' borderRadius='10px' p={2}>
      <ChartContainer>
        <div style={{ padding: '0 1.5rem 1.5rem' }}>
          <div>
            <h3
              style={{
                marginTop: 0,
                color: PrimaryColor,
                fontFamily: 'Montserrat',
              }}
            >
              Active Hours
            </h3>
          </div>
        </div>

        <div style={{ height: '320px', marginLeft: '-2rem' }}>
          <ResponsiveContainer width='99%' height='100%'>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 32,
                left: 28,
                bottom: 16,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='hour'
                height={99}
                interval={0}
                tick={<CustomAxisTick />}
              />
              <YAxis
                allowDecimals={false}
                label={<CustomLabel />}
                style={{ fontFamily: 'Poppins' }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type='monotone'
                dataKey='agents'
                name='Agents'
                stroke='#3569B7'
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </ChartContainer>
    </Box>
  );
};

export default AgentActiveHours;
