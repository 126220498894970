import { Box, Typography, Stack } from '@mui/material';

interface NoResultsFoundProps {
  copy: boolean;
  msg?: string;
}

const NoResultsFound = ({ copy, msg }: NoResultsFoundProps) => {
  return (
    <Box display='flex' justifyContent='center'>
      <Stack>
        <img
          src='/noresults-illustration.svg'
          alt='noresults-illustration'
          style={{
            width: '85%',
            maxWidth: '300px',
            marginLeft: '20px',
            marginTop: '60px',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: '600',
            color: '#484D5E',
            textAlign: 'center',
          }}
        >
          {msg === undefined ? 'No results found' : msg}
        </Typography>
        {copy && (
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '14px',
              color: '#484D5E',
              textAlign: 'center',
            }}
          >
            Enter data or search with other keywords
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default NoResultsFound;
