import axios from 'axios';

import { resolvePromise } from '../Lib/Helper/ErrorHandler';
import { FilterState } from '../Pages/Administration/Component/User';
import { arrayToFilterString } from '../Utility/utils';
import { generalErrorHandler } from './ErrorHandler';
import { RoleContentInterface } from './Role';

import type { AxiosError, AxiosRequestConfig } from 'axios';

export interface UserContentInterface {
  created_at: Date;
  modified_at: Date;
  id: number;
  name: string;
  username: string;
  email: string;
  role: string;
}

export interface UserInterface {
  page_number: number;
  page_size: number;
  total_pages: number;
  has_prev: boolean;
  has_next: boolean;
  content: UserContentInterface[];
}

export interface UserRegisterInterface {
  name: string;
  username: string;
  password: string;
  confirmPassword: string;
  role: string;
  email: string;
}

export interface UserSingleInterface {
  id: number;
  name: string;
  email?: any;
  username: string;
  role: RoleContentInterface;
  permissions: string[];
}

export const getAllUser = async (
  page: number,
  pageSize: number,
  token: string,
  sort: string,
  filter: FilterState
) => {
  try {
    const usernameString =
      filter.username !== '' ? `username %= ${filter.username}` : '';
    const nameString = filter.name !== '' ? `&name %= ${filter.name}` : '';
    const emailString = filter.email !== '' ? `&email %= ${filter.email}` : '';
    const roleString = arrayToFilterString(filter.role, 'role_name', 'name');
    const filterString = usernameString + nameString + emailString + roleString;
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        page_size: pageSize,
        page,
        sort,
        filter: filterString,
      },
    };
    const { data }: { data: UserInterface } = await axios.get(
      `${process.env.REACT_APP_BACKEND}/users/`,
      config
    );
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const addUser = async (token: string, user: UserRegisterInterface) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/users/`,
      {
        name: user.name,
        username: user.username,
        password: user.password,
        role: user.role,
        email: user.email,
      },
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const editUser = async (token: string, user: UserRegisterInterface) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const payload: { password?: string; email: string; name: string } = {
      email: user.email,
      name: user.name,
    };
    if (user.password !== '') {
      payload.password = user.password;
    }
    await axios.patch(
      `${process.env.REACT_APP_BACKEND}/users/${user.username}`,
      payload,
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getUser = async (token: string, username: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND}/users/${username}`,
      config
    );
    const data: UserSingleInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const deleteUser = async (token: string, username: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.delete(
      `${process.env.REACT_APP_BACKEND}/users/${username}`,
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export interface FeatureInterface {
  ca_scoring: boolean;
  rude_words: boolean;
  sentiments: boolean;
}

export const updateUserFeature = async (
  token: string,
  username: string,
  feature: FeatureInterface
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.put(
      `${process.env.REACT_APP_BACKEND}/analytics/settings/users/${username}/features`,
      feature,
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getUserFeature = async (token: string, username: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND}/analytics/settings/users/${username}/features`,
      config
    );
    const data: FeatureInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getUserImage = async (token: string) => {
  const config: AxiosRequestConfig = {
    responseType: 'blob',
    headers: { Authorization: `Bearer ${token}` },
  };

  const [data, error] = await resolvePromise<Blob | null>(
    axios.get(
      `${process.env.REACT_APP_BACKEND}/users/me/profile_picture`,
      config
    )
  );

  if (error) throw Error(error.detail);
  return data;
};

interface UserData {
  id: number;
  name: string;
  username: string;
  role: {
    id: number;
    key: string;
    name: string;
  };
  permissions: string[];
}

export const getUserData = async (token: string) => {
  const [data, error] = await resolvePromise<UserData>(
    axios.get(`${process.env.REACT_APP_BACKEND}/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  );

  if (error) throw Error(error.detail);
  return data;
};

export const updateUserPassword = async (
  token: string,
  old_password: string,
  password: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.patch(
      `${process.env.REACT_APP_BACKEND}/users/me/password`,
      { old_password, password },
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const updateUserFullName = async (token: string, name: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios.patch(
      `${process.env.REACT_APP_BACKEND}/users/me/name`,
      { name },
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const updateUserImage = async (token: string, image_data: File) => {
  try {
    const formData = new FormData();
    formData.append('image_data', image_data);
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    await axios.put(
      `${process.env.REACT_APP_BACKEND}/users/me/profile_picture`,
      formData,
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};
