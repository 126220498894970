import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Login, Replay } from '@mui/icons-material';
import { HeaderGroup } from 'react-table';
import { Button, Typography, Tooltip, Grid } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: '30vw',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modalTitle: {
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    button: {
      marginLeft: '8px',
      border: '0',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
      padding: '5px',
      '&:hover': {
        border: '0',
      },
    },
  })
);

type ModalTableProps = {
  headers: HeaderGroup<Record<string, any>>[];
  applyFilter: () => void;
  cancelFilter?: () => void;
  clearFilter: () => void;
  hasSubheader?: boolean;
};

export default function ModalTable({
  headers,
  applyFilter,
  clearFilter,
  hasSubheader,
}: ModalTableProps) {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <Grid container direction='row' sx={{ fontSize: '14px' }}>
      {headers.map((headerGroup, iHeaderGroup) => (
        <Grid item key={iHeaderGroup}>
          <Grid
            container
            direction='row'
            spacing={0}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, iColumn) => (
              <Grid
                item
                // @ts-ignore
                key={iColumn}
                mb={2}
                // mr={column.canFilter ? 2 : 0}
                {...column.getHeaderProps()}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                  }}
                >
                  {/* @ts-ignore */}
                  {column.canFilter ? column.render('Header') : null}
                </Typography>
                {/* @ts-ignore */}
                <div>{column.canFilter ? column.render('Filter') : null}</div>
                {iColumn === headerGroup.headers.length - 1 && (
                  <div style={{ height: '50px' }}></div>
                )}
              </Grid>
            ))}
            <Grid item mb={2}>
              <Grid container direction='column' justifyContent='flex-end'>
                <Grid
                  item
                  sx={{ marginBottom: hasSubheader ? '34px' : '26px' }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    &nbsp;
                  </Typography>
                </Grid>
                <Grid item sx={{ height: '50px' }}>
                  <Tooltip title='Apply Filter'>
                    <Button
                      onClick={() => {
                        setIsDisabled(true);
                        applyFilter();
                        setIsDisabled(false);
                      }}
                      className={classes.button}
                      disabled={isDisabled}
                      variant='outlined'
                      color='primary'
                      sx={{ height: '100%' }}
                    >
                      <Login />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Reset Filter'>
                    <Button
                      sx={{ height: '100%' }}
                      variant='outlined'
                      color='primary'
                      className={classes.button}
                      disabled={isDisabled}
                      onClick={() => {
                        clearFilter();
                      }}
                    >
                      <Replay />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
