import { useState } from 'react';
import SideBar from '../SideBar/SideBar';
import TopBar from '../TopBar/TopBar';
import { useLocation } from 'react-router-dom';

const Wrapper = () => {
  const [isToggleDrawer, setIsToggleDrawer] = useState(false);
  const [isClickingMenuButton, setIsClickingMenuButton] = useState(false);
  const location = useLocation();

  if (location.pathname === '/') return null;

  return (
    <>
      <TopBar
        onToggleDrawer={() => {
          setIsToggleDrawer((c) => !c);
          setIsClickingMenuButton(true);
        }}
      />
      <SideBar
        isToggleDrawer={isToggleDrawer}
        setIsToggle={(isToggle) => setIsToggleDrawer(isToggle)}
        isClickingMenuBotton={isClickingMenuButton}
        preventClickAway={() => setIsClickingMenuButton(false)}
      />
    </>
  );
};

export default Wrapper;
