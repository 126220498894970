import AgentRankCardContainer from './AgentRankCardContainer';
import AgentStatisticCard from './AgentStatisticCard';
import AgentActiveHours from './AgentActiveHours';
import { Box, Typography, Grid } from '@mui/material';
import { AgentMonitorContainer } from '../CallMonitorStyle';
import { RangeState } from './ConversationMonitor';
import CustomerOpinionCard from './CustomerOpinionCard';

interface AgentMonitorProps {
  range: RangeState;
}

const AgentMonitor = ({ range }: AgentMonitorProps) => {
  return (
    <div id='agent-monitor'>
      <Box mt={4}>
        <Typography
          variant='h6'
          color='primary'
          sx={{ fontFamily: 'Montserrat', fontWeight: 700 }}
        >
          CS Performance
        </Typography>
        <hr style={{ marginBottom: '1.5rem' }} />
        <AgentActiveHours range={range} />

        <AgentMonitorContainer>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 1 }}>
              <AgentStatisticCard range={range} />
            </Grid>

            <AgentRankCardContainer range={range} />
            <Grid item xs={12} sm={6} md={3} order={{ xs: 4, sm: 2, md: 4 }}>
              <CustomerOpinionCard
                range={range}
                showTotal={false}
                opinionData={null}
              />
            </Grid>
          </Grid>
        </AgentMonitorContainer>
      </Box>
    </div>
  );
};

export default AgentMonitor;
