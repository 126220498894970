import { useCallback, useMemo, useState } from 'react';

interface UseClientPagination {
  data: any[];
}

const useClientPagination = ({ data }: UseClientPagination) => {
  const [pagination, setPagination] = useState({
    perPage: 7,
    page: 1,
  });

  const changePage = useCallback((page: number) => {
    setPagination((c) => ({ ...c, page }));
  }, []);

  const paginatedData = useMemo(
    () =>
      data.slice(
        (pagination.page - 1) * pagination.perPage,
        pagination.page * pagination.perPage
      ),
    [data, pagination.page, pagination.perPage]
  );

  const totalPage = useMemo(
    () => Math.ceil(data.length / pagination.perPage),
    [data.length, pagination.perPage]
  );

  return {
    data: paginatedData,
    pagination,
    totalPage,
    action: {
      changePage,
    },
  };
};

export default useClientPagination;
