import { AxiosError } from 'axios';

interface ErrorType {
  detail?: string | string[];
  message?: string;
}

export const generalErrorHandler = (errorParam: AxiosError): string | false => {
  const error = errorParam as AxiosError<ErrorType>;
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { status, data } = error.response;
    if (data === undefined) {
      // Blocked by client adblocker
      return `Something went wrong, error code: ${status}. Please check your internet connection or disable your browser's adblocker.`;
    } else {
      if (status === 403 || status === 401) {
        return false;
      } else if (status === 422) {
        const { detail } = data;
        if (detail !== undefined) {
          if (detail.length === 0 || typeof detail === 'string') {
            return `Something went wrong, error code: ${status}`;
          }
          const message: string[] = [];
          message.push(`Something went wrong, error code: ${status}`);
          detail.forEach((element: any) => {
            if (typeof element === 'string') {
              message.push(element);
            } else {
              if (element.msg !== undefined) {
                message.push(element.msg);
              }
            }
          });
          return message.join('; ');
        } else {
          return `Something went wrong, error code: ${status}`;
        }
      } else {
        if (data.message !== undefined) {
          if (typeof data.message === 'string') {
            return data.message;
          } else {
            return error.response.statusText
              ? error.response.statusText
              : `Something went wrong, error code: ${status}`;
          }
        } else {
          return error.response.statusText
            ? error.response.statusText
            : `Something went wrong, error code: ${status}`;
        }
      }
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return 'Connection lost';
  } else {
    // Something happened in setting up the request that triggered an Error
    return 'Something went wrong';
  }
};
