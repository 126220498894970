import React, { useRef } from 'react';
import { Button, Popover, Stack } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, Visibility } from '@mui/icons-material';

type ShowNProps = {
  setVal: (pageSize: number) => void;
  curVal: number;
  listVal: Array<number>;
};

const ShowN = ({ setVal, curVal, listVal }: ShowNProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <Stack sx={{ marginRight: '6px' }}>
      <Button
        ref={ref}
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
        startIcon={<Visibility sx={{ color: '#333333' }} />}
        endIcon={
          open ? (
            <ArrowDropUp sx={{ color: '#333333' }} />
          ) : (
            <ArrowDropDown sx={{ color: '#333333' }} />
          )
        }
        sx={{
          fontFamily: 'Poppins',
          fontSize: '14px',
          backgroundColor: '#E2E4E6',
          boxShadow: '0',
          color: 'black',
          minWidth: '140px',
          padding: '8px',
          '&:hover, &:focus': {
            backgroundColor: '#DADCDE',
            boxShadow: '0',
          },
        }}
      >
        Show {curVal}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack
          sx={{
            minWidth:
              ref !== null
                ? ref.current !== null
                  ? ref.current.offsetWidth
                  : '140px'
                : '140px',
          }}
        >
          {listVal.map((val, index) => {
            return (
              <Button
                aria-describedby={id}
                variant='contained'
                key={`show-${val}-${index}`}
                onClick={() => {
                  setVal(val);
                  handleClose();
                }}
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  backgroundColor: val !== curVal ? '#ffffff' : '#EBEFF4',
                  color: 'black',
                  borderRadius: '0',
                  boxShadow: '0',
                  justifyContent: 'flex-start',
                  '&:hover, &:focus': {
                    backgroundColor: '#F5F5F5',
                    boxShadow: '0',
                  },
                }}
              >
                Show {val}
              </Button>
            );
          })}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ShowN;
