import useClientPagination from '@/Lib/Hooks/useClientPagination';

import { useContext } from 'react';
import { getAnalyticsConcepts } from '../../../Model/TopicSentiment';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { ChartContainer } from '../CallMonitorStyle';
import { RangeState } from './ConversationMonitor';
import { PrimaryColor } from '../../../Components/Style/Style';
import { useQuery } from 'react-query';
import { Box, Pagination } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface ChartProps {
  range: RangeState;
}

interface CustomTooltipProps {
  payload?: any;
  label?: string;
  active?: boolean;
}

const CustomTooltip = ({ payload, label, active }: CustomTooltipProps) => {
  const valueArray =
    !payload || !payload.length ? [] : payload.map((item: any) => item.value);
  const total =
    valueArray.length === 0
      ? 0
      : valueArray.reduce((prev: number, next: number) => prev + next);
  const finalItems = [
    {
      fill: '#444',
      name: 'Total',
      value: total || 0,
    },
    ...(!payload ? [] : payload),
  ];
  if (active) {
    return (
      <div
        style={{
          borderRadius: '10px',
          background: 'white',
          border: '1px solid rgba(0, 55, 112, 0.24)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px 10px 0 0',
            background: '#f2fcff',
            color: '#065dcc',
            padding: '0.25rem',
            fontFamily: 'Poppins',
          }}
        >
          <h4>{label}</h4>
        </div>
        <div style={{ padding: '0 1rem' }}>
          {finalItems.map((item: any, index: any) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: '150px',
                margin: '8px 0',
                fontFamily: 'Poppins',
              }}
              key={`custom-tooltip-crarttopicdist-${index}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    background: item.fill,
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                {item.name}
              </div>
              <h4 style={{ color: PrimaryColor, fontWeight: 700 }}>
                {item.value}
              </h4>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const ChartTopicSentimentDistributions = ({ range }: ChartProps) => {
  const authentication = useContext(AuthContext);

  const { data } = useQuery(
    ['getConceptsAnalytics', authentication, range],
    () =>
      getAnalyticsConcepts(
        0,
        1000,
        authentication.auth.data.token,
        range.startDate,
        range.endDate,
        '',
        ''
      ),
    {
      onSuccess: (data) => {
        if (data === false) {
          authentication.func.changeAuthToFalse();
        }
      },
    }
  );

  const {
    data: paginatedData,
    totalPage,
    pagination,
    action: { changePage },
  } = useClientPagination({
    data:
      typeof data !== 'string' && typeof data !== 'boolean' && data
        ? data.content.sort((a, b) => {
            const totalA = a.positive + a.neutral + a.negative;
            const totalB = b.positive + b.neutral + b.negative;

            return totalB - totalA;
          })
        : [],
  });

  if (typeof data === 'string') return <>{data}</>;

  return (
    <ChartContainer>
      <div style={{ padding: '0' }}>
        <div style={{ marginBottom: '1.5rem' }}>
          <h3
            style={{
              marginTop: 0,
              fontFamily: 'Montserrat',
              color: PrimaryColor,
            }}
          >
            Topic Distributions
          </h3>
        </div>
        <div style={{ height: '320px', marginLeft: '-2rem' }}>
          <ResponsiveContainer width='99%' height='100%'>
            <BarChart
              data={paginatedData}
              margin={{
                bottom: 16,
              }}
            >
              <XAxis dataKey='concept' style={{ fontFamily: 'Poppins' }} />
              <YAxis allowDecimals={false} style={{ fontFamily: 'Poppins' }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                barSize={30}
                dataKey='negative'
                stackId='a'
                fill='#FB4848'
                name='Negative'
              />
              {/* <Bar
                barSize={30}
                dataKey='neutral'
                stackId='a'
                fill='#006EFE'
                name='Neutral'
              /> */}
              <Bar
                barSize={30}
                dataKey='positive'
                stackId='a'
                fill='#3FE4BC'
                name='Positive'
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={totalPage}
          page={pagination.page}
          onChange={(_, value) => changePage(value)}
          showFirstButton
          showLastButton
          variant='outlined'
          shape='rounded'
        />
      </Box>
    </ChartContainer>
  );
};

export default ChartTopicSentimentDistributions;
