import * as Yup from 'yup';
import LoginBanner from '../../Assets/login.png';
import Logo from '../../Assets/logo-dark.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import Loading from '../../Components/Loading/Loading';
import PasswordInputField from '../../Components/InputField/Password';
import Footer from '@/Components/Footer/Footer';
import { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { SignIn } from './LoginStyle';
import { useNavigate } from 'react-router-dom';
import { authUser } from '../../Model/Authentication';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { Alert } from '@mui/material';
import { Close } from '@mui/icons-material';
import { PrimaryColor } from '../../Components/Style/Style';
import { getUserData, getUserImage } from '../../Model/User';
import { getCredential } from '../../Session/Session';
import { useFormik } from 'formik';

const Login = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .max(50, 'Username cannot be longer than 50 characters')
        .required('Username is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async ({ username, password }) => {
      try {
        const resultAuth = await authUser({ username, password });

        if (resultAuth) {
          const resultData = await getUserData(resultAuth.access_token);
          const resultImage = await getUserImage(resultAuth.access_token);

          if (resultData) {
            auth.func.changeAuthToTrue({
              id: resultData.id,
              username: username,
              token: resultAuth.access_token,
              permissions: resultData.permissions,
              image: resultImage ? URL.createObjectURL(resultImage) : '',
            });

            if (
              resultData.permissions.includes('statistics.agents') ||
              resultData.permissions.includes('statistics.conversations')
            ) {
              navigate('/call-monitor');
            } else {
              navigate('/conversation');
            }
          } else {
            throw Error('Unable to authenticate');
          }
        } else {
          throw Error('Unable to authenticate');
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('Unknown error');
        }
      }
    },
  });

  useEffect(() => {
    const credential = getCredential();
    if (credential.isAuth) {
      getUserData(credential.data.token)
        .then((result) => {
          if (result) {
            if (
              result.permissions.includes('statistics.agents') ||
              result.permissions.includes('statistics.conversations')
            ) {
              navigate('/call-monitor');
            } else {
              navigate('/conversation');
            }
          } else {
            throw Error('User not found');
          }
        })
        .catch(() => {
          auth.func.changeAuthToFalse();
        });
    } else {
      setIsLoading(false);
    }
  }, [navigate, auth]);

  if (isLoading) return <Loading />;

  return (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      <Grid
        container
        direction='row'
        sx={{ width: '100%', height: '100%' }}
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          <img src={LoginBanner} alt='banner-login' style={{ width: '100%' }} />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              margin: 'auto',
              width: '90%',
              padding: '40px 0px',
              backgroundColor: 'transparent',
              //   borderRadius: '8px',
              //   boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.12)',
            }}
          >
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{ width: '85%', margin: 'auto' }}
            >
              <div style={{ textAlign: 'center' }}>
                <img src={Logo} alt='logo-coala' style={{ width: '50%' }} />
              </div>
              <SignIn>Sign in to your account</SignIn>
              <Box>
                <TextField
                  name='username'
                  id='username'
                  label='Username'
                  margin='normal'
                  size='small'
                  fullWidth
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  autoFocus
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={
                    formik.touched.username
                      ? formik.errors.username
                        ? formik.errors.username
                        : ' '
                      : ' '
                  }
                  sx={{
                    label: {
                      fontFamily: 'Poppins',
                    },
                  }}
                  InputProps={{
                    style: { fontFamily: 'Poppins' },
                  }}
                  autoComplete='username'
                />
              </Box>
              <Box>
                <PasswordInputField
                  outlinedInputProps={{
                    name: 'password',
                    id: 'password',
                    label: 'Password',
                    value: formik.values.password,
                    onChange: formik.handleChange,
                  }}
                  touched={formik.touched.password}
                  helperText={formik.errors.password}
                />
              </Box>

              {error && (
                <Alert
                  sx={{ marginBottom: '24px' }}
                  action={
                    <IconButton
                      onClick={() => setError('')}
                      color='inherit'
                      size='small'
                    >
                      <Close />
                    </IconButton>
                  }
                  severity='error'
                >
                  {error}
                </Alert>
              )}

              <Box>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  disabled={
                    !formik.values.username ||
                    !formik.values.password ||
                    formik.isSubmitting
                  }
                  sx={{ fontFamily: 'Poppins' }}
                >
                  {formik.isSubmitting ? (
                    <ClipLoader size={20} color={PrimaryColor} />
                  ) : (
                    'Sign In'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
