import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { generalErrorHandler } from './ErrorHandler';
export interface CustomerOpinionItems {
  good?: number;
  neutral?: number;
  bad?: number;
}

export interface CustomerOpinionInterface {
  customer_opinions_count: CustomerOpinionItems;
}

export const DummyCustomerOpinion: CustomerOpinionInterface = {
  customer_opinions_count: {
    good: 70,
    neutral: 20,
    bad: 10,
  },
};

export const getCustomerOpinion = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/customers/opinions`,
      config
    );
    const data: CustomerOpinionInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};
