import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Button,
} from '@mui/material';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { toastConfig } from '../../../Components/Style/Style';
import { capitalizeWord, sentimentColor } from '../../../Utility/utils';
import { RangeState } from './ConversationMonitor';
import { getTopicSentimentRanks } from '../../../Model/TopicSentiment';
import NoResultsFound from '@/Components/CustomReactTable/Components/NoResultsFound';
import { ArrowRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface TopicSentimentProps {
  name: string;
  value: number;
}

interface TopicSentimentRanksCardProps {
  sentiment: 'positive' | 'neutral' | 'negative';
  range: RangeState;
}

const TopicSentimentRanksCard = ({
  sentiment,
  range,
}: TopicSentimentRanksCardProps) => {
  const color = sentimentColor[sentiment];
  const authentication = useContext(AuthContext);
  const [content, setContent] = useState<TopicSentimentProps[]>([]);
  const [maxValue, setMaxValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDurationStatistics = async () => {
      const result = await getTopicSentimentRanks(
        sentiment,
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else if (typeof result === 'string') {
        toast.dark(result, toastConfig);
      } else {
        if (result.length > 0) {
          const tempResult = result
            .map((item: any) => ({
              name: item.concept,
              value: item.count,
            }))
            .sort((item1: any, item2: any) => {
              return item2.value - item1.value;
            })
            .slice(0, 5);
          const tempMaxValue = tempResult[0].value;
          setContent(tempResult);
          setMaxValue(tempMaxValue);
        } else {
          setContent(result);
        }
      }
    };
    fetchDurationStatistics();
  }, [sentiment, range, authentication]);

  return (
    <Box
      border='1px solid #D7D7D7'
      borderRadius='8px'
      py={2}
      px={2}
      sx={{ height: '90%' }}
    >
      <Typography
        variant='h6'
        sx={{
          fontFamily: 'Montserrat',
          color: '#003770',
          width: '100%',
          textAlign: 'center',
          fontWeight: 700,
        }}
      >
        Most {capitalizeWord(sentiment)} Topics
      </Typography>
      <br />
      {maxValue > 0 ? (
        <>
          {content.map(({ name, value }) => {
            const widthValue = maxValue === 0 ? 0 : (value / maxValue) * 100;
            return (
              <Button
                key={`${sentiment}-${name}`}
                onClick={() => navigate(`/topic-sentiment/${encodeURI(name)}`)}
                sx={{ width: '100%', textAlign: 'left' }}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 700,
                    }}
                  >
                    {name}
                  </Typography>
                  <LinearProgress
                    variant='determinate'
                    value={widthValue}
                    sx={{
                      height: 10,
                      my: 0.75,
                      borderRadius: 5,
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        bgcolor: '#e6e6e6',
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: 5,
                        bgcolor: color,
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    Total <span style={{ fontWeight: 700 }}>{value}</span>
                  </Typography>
                </Box>
              </Button>
            );
          })}
          <Button
            sx={{
              fontSize: '14px',
              fontFamily: 'Poppins',
              marginBottom: 2,
              fontWeight: 700,
              color: '#2E58C7',
            }}
            onClick={() =>
              navigate('/topic-sentiment', { state: `~${sentiment}` })
            }
          >
            More <ArrowRight />
          </Button>
        </>
      ) : (
        <NoResultsFound
          copy={false}
          msg={`${capitalizeWord(sentiment)} Topics not available`}
        />
      )}
    </Box>
  );
};

export default TopicSentimentRanksCard;
