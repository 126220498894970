import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import React from 'react';
import Popover from '@mui/material/Popover';

import { ArrowDropDown, DateRange } from '@mui/icons-material';
import { Button, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { getStringDate } from '../../Utility/utils';
import { getDate } from '../../Session/Session';

interface DateRangePickerState {
  startDate: Date;
  endDate: Date;
  key?: string;
}

interface DatePickerProps {
  display: string;
  startDate: string;
  endDate: string;
  setStartEndDate: (range: DateRangePickerState) => void;
  right: boolean;
}

export const CustomDateRangePicker = ({
  display,
  startDate,
  endDate,
  setStartEndDate,
  right,
}: DatePickerProps) => {
  const storedDate = getDate();

  const [state, setState] = useState<DateRangePickerState>({
    startDate: dayjs(storedDate.startDate).toDate(),
    endDate: dayjs(storedDate.endDate).toDate(),
    key: 'selection',
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-picker-popover' : undefined;

  if (Object.keys(state).length !== 0 && state.constructor === Object) {
    if (state.endDate !== undefined && state.startDate !== undefined) {
      startDate = getStringDate(state.startDate);
      endDate = getStringDate(state.endDate);
    }
  }

  useEffect(() => {
    dayjs.extend(dayOfYear);
  }, []);

  return (
    <div>
      <Box
        sx={{
          mt: 3,
          alignItems: 'center',
          justifyContent: 'flex-end',
          display,
          width: '100%',
        }}
      >
        <Button
          aria-describedby={id}
          variant='contained'
          onClick={handleClick}
          startIcon={<DateRange sx={{ color: '#1E3066' }} />}
          endIcon={<ArrowDropDown />}
          sx={{
            boxShadow: '0',
            height: '41px',
            bgcolor: '#F0F2F4',
            color: '#333333',
            fontFamily: 'Poppins',
            '&:hover, &:focus': {
              backgroundColor: '#E2E4E6',
              boxShadow: '0',
            },
            width: '100%',
          }}
        >
          {process.env.NODE_ENV !== 'test' ? startDate + ' - ' + endDate : ''}
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: right ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: right ? 'right' : 'left',
        }}
      >
        {/* @ts-ignore */}
        <DateRangePicker
          onChange={(item: any) => {
            setState(item.selection);
            setStartEndDate({
              startDate: item.selection.startDate,
              endDate: item.selection.endDate,
            });
          }}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[state]}
          direction='horizontal'
          staticRanges={[
            {
              label: 'Today',
              range: () => ({
                startDate: new Date(),
                endDate: new Date(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: 'Yesterday',
              range: () => ({
                startDate: dayjs().add(-1, 'day').toDate(),
                endDate: dayjs().add(-1, 'day').toDate(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: 'This Week',
              range: () => ({
                startDate: dayjs().day(0).toDate(),
                endDate: dayjs().day(6).toDate(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: 'Last Week',
              range: () => ({
                startDate: dayjs().day(0).add(-7, 'day').toDate(),
                endDate: dayjs().day(6).add(-7, 'day').toDate(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: 'This Month',
              range: () => ({
                startDate: dayjs().date(1).toDate(),
                endDate: dayjs().add(1, 'month').date(0).toDate(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: 'Last Month',
              range: () => ({
                startDate: dayjs().subtract(1, 'month').date(1).toDate(),
                endDate: dayjs().date(0).toDate(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: 'This Year',
              range: () => ({
                startDate: dayjs().dayOfYear(1).toDate(),
                endDate: dayjs()
                  .dayOfYear(1)
                  .add(1, 'year')
                  .add(-1, 'day')
                  .toDate(),
              }),
              isSelected() {
                return false;
              },
            },
            {
              label: 'Last Year',
              range: () => ({
                startDate: dayjs().dayOfYear(1).add(-1, 'year').toDate(),
                endDate: dayjs().dayOfYear(1).add(-1, 'day').toDate(),
              }),
              isSelected() {
                return false;
              },
            },
          ]}
        />
      </Popover>
    </div>
  );
};

export default CustomDateRangePicker;
