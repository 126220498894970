import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { generalErrorHandler } from './ErrorHandler';

export interface Content {
  concept: string;
  positive: number;
  neutral: number;
  negative: number;
  conversation_count: number;
}

export interface AnalyticsConcepts {
  page_number: number;
  page_size: number;
  total_pages: number;
  has_prev: boolean;
  has_next: boolean;
  content: Content[];
}

export const getAnalyticsConcepts = async (
  page: number,
  page_size: number,
  token: string,
  startDate: string,
  endDate: string,
  filter: string,
  sort: string
) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        page,
        page_size,
        from: startDate,
        to: endDate,
        filter,
        sort,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/analytics/concepts`,
      config
    );
    const data = (await result.data) as AnalyticsConcepts;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getTopicSentimentStatistics = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/customers/sentiments/series`,
      config
    );
    const data: any = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getTopicSentimentSummary = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/customers/sentiments/summary`,
      config
    );
    const data: any = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getTopicSentimentRanks = async (
  sentiment: 'positive' | 'neutral' | 'negative',
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/customers/concepts/ranks/${sentiment}`,
      config
    );
    const data: any = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export interface TopicSentimentDetailInterface {
  page_number: number;
  page_size: number;
  total_pages: number;
  has_prev: boolean;
  has_next: boolean;
  content: TopicSentimentDetailContentInterface[];
}

export interface TopicSentimentDetailContentInterface {
  id: number;
  conversation_name: string;
  agent_id: string;
  agent_name: string;
  customer_id: string;
  customer_name: string;
  duration: number;
  start_time: string;
  positive: number;
  neutral: number;
  negative: number;
}

export type TopicDetailInformationInterface = {
  positive: number;
  negative: number;
  neutral: number;
};

export const DummyTopicDetailInformation: TopicDetailInformationInterface = {
  positive: 0,
  negative: 0,
  neutral: 0,
};

export const getTopicDetailInformation = async (
  token: string,
  startDate: string,
  endDate: string,
  topic: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start: startDate,
        end: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/analytics/concepts/${topic}/detail`,
      config
    );
    const data: TopicDetailInformationInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getTopicList = async (
  page: number,
  pageSize: number,
  token: string,
  startDate: string,
  endDate: string,
  topic: string
) => {
  try {
    const params = {
      start: startDate,
      end: endDate,
      page: page,
      page_size: pageSize,
      concept: topic,
    };
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    };
    const { data }: { data: TopicSentimentDetailInterface } = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/analytics/concepts/${topic}/conversations`,
      config
    );
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};
