import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { FilterState } from '../Pages/Administration/Component/AgentAdministration';
import { FilterState as FilterStatePerformance } from '../Pages/Agent/Component/AgentTableContainer';
import { FilterState as FilterStateAgentPerformance } from '../Pages/AgentDetail/Component/AgentHistory';
import {
  arrayToFilterString,
  arrayToFilterStringBoolean,
  arrayToFilterStringInBetween,
} from '../Utility/utils';
import { CustomerOpinionItems } from './CustomerOpinion';
import { generalErrorHandler } from './ErrorHandler';

export interface AgentInterface {
  id?: number;
  agent_id: string;
  name: string;
  gender: 'male' | 'female';
  active: boolean;
}

export interface AgentContentTableInterface {
  created_at: Date;
  modified_at: Date;
  id: number;
  agent_id: string;
  name: string;
  gender: string;
  active: boolean;
}

export interface AgentTableInterface {
  page_number: number;
  page_size: number;
  total_pages: number;
  has_prev: boolean;
  has_next: boolean;
  content: AgentContentTableInterface[];
}

export const getAllAgent = async (
  page: number,
  pageSize: number,
  token: string,
  sort: string,
  filter: FilterState
) => {
  try {
    const agentString =
      filter.agent_id !== '' ? `&agent_id %= ${filter.agent_id}` : '';
    const nameString = filter.name !== '' ? `&name %= ${filter.name}` : '';
    const genderString = arrayToFilterString(filter.gender, 'gender', 'key');
    const statusString = arrayToFilterStringBoolean(
      filter.active,
      'active',
      'key'
    );
    const filterString = agentString + nameString + genderString + statusString;
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        page_size: pageSize,
        page,
        sort,
        filter: filterString,
      },
    };
    const { data }: { data: AgentTableInterface } = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/agents/`,
      config
    );
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const addAgent = async (token: string, agent: AgentInterface) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.post(
      `${process.env.REACT_APP_BACKEND}/agents/`,
      agent,
      config
    );
    const data = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getAgentById = async (token: string, id: string) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/agents/${id}`,
      config
    );
    const data: AgentInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const editAgent = async (
  token: string,
  agent: AgentInterface,
  id: string
) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const dataAgent = {
      name: agent.name,
      gender: agent.gender,
      active: agent.active,
    };
    await Axios.patch(
      `${process.env.REACT_APP_BACKEND}/agents/${id}`,
      dataAgent,
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const removeAgent = async (token: string, id: string) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await Axios.delete(`${process.env.REACT_APP_BACKEND}/agents/${id}`, config);
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export interface AgentSummaryInterface {
  rude_words_count: number;
  average_ca_score: number;
  ca_below_threshold: number;
  ca_above_threshold: number;
  active_agents: number;
}

export const getAgentSummaryStatistics = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/agents/summary`,
      config
    );
    const data: AgentSummaryInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export interface AgentRankingInterface {
  id: number;
  name: string;
  agent_id: string;
  gender: string;
  score: number;
  calls: number;
}

export const getAgentRanking = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/agents/scores`,
      config
    );
    const data: AgentRankingInterface[] = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export interface ActiveHoursInterface {
  hour: string;
  calls: number;
  agents: number;
}

export const getActiveHours = async (
  token: string,
  startDate: string,
  endDate: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/conversations/active_hours`,
      config
    );
    const data: ActiveHoursInterface[] = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

type RudeWordsInterface = {
  word: string;
  conversation_id: number;
  text_id: number;
};

export type AgentConversationInformationInterface = {
  name: string;
  status: boolean;
  gender: string;
  count: number;
  average_duration: number;
  rank: number;
  agents_count: number;
  average_score: number;
  min_score: number;
  max_score: number;
  rude_words: RudeWordsInterface[];
  rude_words_count: number;
  customer_opinions_count: CustomerOpinionItems;
};

export const DummyAgentConversationInformation: AgentConversationInformationInterface =
  {
    name: '-999',
    status: true,
    gender: 'male',
    count: 0,
    average_duration: 0,
    rank: 0,
    agents_count: 0,
    average_score: 0,
    min_score: 0,
    max_score: 0,
    rude_words: [
      {
        word: '',
        conversation_id: -1,
        text_id: -1,
      },
    ],
    rude_words_count: 0,
    customer_opinions_count: {},
  };

export const getAgentConversationInformation = async (
  token: string,
  startDate: string,
  endDate: string,
  id: string
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/agents/${id}/performance`,
      config
    );
    const data: AgentConversationInformationInterface = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export interface AgentConversationContentInterface {
  conversation_id: number;
  start_time: Date;
  customer: string;
  duration: number;
  rude_words_count: number;
  score: number;
}

export interface AgentConversationInterface {
  page_number: number;
  page_size: number;
  total_pages: number;
  has_prev: boolean;
  has_next: boolean;
  content: AgentConversationContentInterface[];
}

export const getAgentConversation = async (
  page: number,
  pageSize: number,
  token: string,
  sort: string,
  filter: FilterStateAgentPerformance,
  startDate: string,
  endDate: string,
  id: string,
  conversationId: number
) => {
  const durationString = arrayToFilterStringInBetween(
    filter.duration.from,
    filter.duration.to,
    'duration'
  );
  const customerString = arrayToFilterString(
    filter.customer,
    'customer_id',
    'customer_id'
  );
  const totalRudeString = arrayToFilterStringInBetween(
    filter.rude_words_count.from,
    filter.rude_words_count.to,
    'rude_words_count'
  );
  // const scoreString = arrayToFilterStringInBetween(
  //   filter.score.from,
  //   filter.score.to,
  //   'score'
  // );
  // let filterString = `${durationString}${customerString}&${totalRudeString}&${scoreString}`;
  let filterString = `${durationString}${customerString}&${totalRudeString}`;
  if (conversationId !== -1) {
    filterString = filterString + `&id = ${conversationId}`;
  }
  try {
    const params = {
      from: startDate,
      to: endDate,
      page,
      page_size: pageSize,
      sort,
      filter: filterString,
    };
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    };
    const { data }: { data: AgentConversationInterface } = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/agents/${id}/conversations`,
      config
    );
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};
export interface AgentListStatisticsContentInterface {
  agent_id: string;
  name: string;
  average_call_duration: number;
  total_calls: number;
  rude_words_count: number;
  avg_score: number;
  active: boolean;
}

export interface AgentListStatisticsInterface {
  page_number: number;
  page_size: number;
  total_pages: number;
  has_prev: boolean;
  has_next: boolean;
  content: AgentListStatisticsContentInterface[];
}

export const getAgentListStatistics = async (
  page: number,
  pageSize: number,
  token: string,
  sort: string,
  filter: FilterStatePerformance,
  startDate: string,
  endDate: string
) => {
  const agentString =
    filter.agent_id !== '' ? `&agent_id %= ${filter.agent_id}` : '';
  const nameString = filter.name !== '' ? `&name %= ${filter.name}` : '';
  const statusString = arrayToFilterStringBoolean(
    filter.active,
    'active',
    'key'
  );
  const averageString = arrayToFilterStringInBetween(
    filter.average_call_duration.from,
    filter.average_call_duration.to,
    'average_call_duration'
  );
  const totalCallString = arrayToFilterStringInBetween(
    filter.total_calls.from,
    filter.total_calls.to,
    'total_calls'
  );
  const totalRudeString = arrayToFilterStringInBetween(
    filter.rude_words_count.from,
    filter.rude_words_count.to,
    'rude_words_count'
  );
  const avgScoreString = arrayToFilterStringInBetween(
    filter.avg_score.from,
    filter.avg_score.to,
    'avg_score'
  );
  const filterString =
    agentString +
    nameString +
    statusString +
    `&${averageString}` +
    `&${totalCallString}` +
    `&${totalRudeString}` +
    `&${avgScoreString}`;
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        page_size: pageSize,
        from: startDate,
        to: endDate,
        page,
        sort,
        filter: filterString,
      },
    };
    const { data }: { data: AgentListStatisticsInterface } = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/agents/performances`,
      config
    );
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export type AgentListSelectType = {
  agent_id: string;
  name: string;
};

export const getAllAgentListSelect = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/agents/list`,
      config
    );
    const data: AgentListSelectType[] = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getAllAgentListSelectActive = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/agents/list-active`,
      config
    );
    const data: AgentListSelectType[] = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getThreshold = async (token: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/statistics/agents/score_threshold`,
      config
    );
    const data: number = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const updateAgentPicture = async (
  token: string,
  id: string,
  image_data: File
) => {
  try {
    const formData = new FormData();
    formData.append('image_data', image_data);
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    await Axios.put(
      `${process.env.REACT_APP_BACKEND}/agents/${id}/profile_picture`,
      formData,
      config
    );
    return true;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};

export const getAgentPicture = async (token: string, id: string) => {
  try {
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    };
    const result = await Axios.get(
      `${process.env.REACT_APP_BACKEND}/agents/${id}/profile_picture`,
      config
    );
    const data: any = await result.data;
    return data;
  } catch (error) {
    return generalErrorHandler(error as AxiosError);
  }
};
