import { PrimaryColor } from '../../Components/Style/Style';
import styled from 'styled-components';

export const TopicSentimentDetailTitle = styled.div`
  color: ${PrimaryColor};
  padding-bottom: 0.5rem;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
`;
