import AgentRankCard from './AgentRankCard';
import { useContext, useEffect, useState } from 'react';
import { AgentRankingInterface, getAgentRanking } from '../../../Model/Agent';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { RangeState } from './ConversationMonitor';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../Components/Style/Style';
import { copyObject } from '@/Utility/utils';
import { Grid } from '@mui/material';

interface AgentRankCardContainerProps {
  range: RangeState;
}

const AgentRankCardContainer = ({ range }: AgentRankCardContainerProps) => {
  const authentication = useContext(AuthContext);
  const [data, setData] = useState<AgentRankingInterface[]>([]);

  useEffect(() => {
    const fetchAgentRanking = async () => {
      const result = await getAgentRanking(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else if (typeof result === 'string') {
        toast.dark(result, toastConfig);
      } else {
        const tempResult = copyObject(result);
        tempResult.sort((item1: any, item2: any) => {
          return item1.score - item2.score;
        });
        setData(tempResult);
      }
    };
    fetchAgentRanking();
  }, [range, authentication]);

  const dataHigh = data.slice().reverse();

  return (
    <>
      <Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 3, md: 2 }}>
        <AgentRankCard data={dataHigh} title='Top Performance Agent' />
      </Grid>

      <Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 4, md: 3 }}>
        <AgentRankCard data={data} title='Lowest Performance Agent' isLow />
      </Grid>
    </>
  );
};

export default AgentRankCardContainer;
