import axios from 'axios';

import { resolvePromise } from '../Lib/Helper/ErrorHandler';

interface AuthenticationInterface {
  username: string;
  password: string;
}

interface AuthenticationResponse {
  access_token: string;
  token_type: string;
}

export const authUser = async (user: AuthenticationInterface) => {
  const [data, error] = await resolvePromise<AuthenticationResponse>(
    axios.post(`${process.env.REACT_APP_BACKEND}/users/login`, user)
  );

  if (error) throw Error(error.detail);
  return data;
};
