import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { PrimaryColor, toastConfig } from '../../../Components/Style/Style';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { toast } from 'react-toastify';
import {
  TopicDetailInformationInterface,
  DummyTopicDetailInformation,
  getTopicDetailInformation,
  TopicSentimentDetailInterface,
  getTopicList,
} from '../../../Model/TopicSentiment';
import { RangeTypeTopicDetail } from '../TopicSentimentDetail';
import { useQuery } from 'react-query';

type ItemProps = {
  label: string;
  value: JSX.Element;
};

const TopicDetailInformationItem = ({ label, value }: ItemProps) => {
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      fontFamily='Poppins'
      mt={2}
    >
      <Grid item width='120px' display='flex' alignItems='center'>
        {label}
      </Grid>
      <Grid item>: {value}</Grid>
    </Grid>
  );
};

type TopicDetailInformationProps = {
  range: RangeTypeTopicDetail;
};

const TopicDetailInformation = ({ range }: TopicDetailInformationProps) => {
  const { topic } = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const authentication = useContext(AuthContext);
  const [data, setData] = useState<TopicDetailInformationInterface>(
    DummyTopicDetailInformation
  );

  useEffect(() => {
    if (topic === undefined) return;
    const fetchTopicInformation = async () => {
      const result = await getTopicDetailInformation(
        authentication.auth.data.token,
        range.startDate,
        range.endDate,
        topic
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else if (typeof result === 'string') {
        toast.dark(result, toastConfig);
      } else {
        setData(result);
      }
    };

    fetchTopicInformation();
  }, [range, authentication, topic]);

  const { data: topicSentimentDetail } = useQuery<
    TopicSentimentDetailInterface | string | false
  >(
    ['FetchAllAgentConversationUnpaginated', range],
    () => {
      if (topic === undefined) {
        navigate(-1);
        return 'Topic Undefined';
      }

      return getTopicList(
        0,
        1000,
        authentication.auth.data.token,
        range.startDate,
        range.endDate,
        topic
      );
    },
    {
      onError: () => {
        toast.warning('Something went wrong', toastConfig);
      },
    }
  );

  return (
    <div id='agent-detail-information'>
      <Grid
        container
        direction='column'
        p={3}
        bgcolor='#FAFBFE'
        boxShadow='4px 4px 4px rgba(136, 184, 255, 0.16)'
        borderRadius='8px'
        spacing={0}
      >
        <Grid
          item
          p={0}
          mb={2}
          sx={{
            color: PrimaryColor,
            fontSize: '16px',
            fontWeight: 700,
          }}
        >
          {topic?.toUpperCase()}
        </Grid>
        <Grid
          item
          p={4}
          mb={2}
          bgcolor='white'
          borderRadius='8px'
          justifyContent='center'
          sx={{ boxShadow: '0px 10px 20px rgba(19, 37, 70, 0.1)' }}
        >
          <Grid
            container
            bgcolor='transparent'
            p={2}
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            spacing={1}
          >
            <Grid item>
              <span
                style={{
                  margin: 0,
                  fontFamily: 'Montserrat',
                  color: '#4A72BB',
                  fontSize: '36px',
                  fontWeight: 700,
                }}
              >
                {topicSentimentDetail &&
                  typeof topicSentimentDetail !== 'string' &&
                  topicSentimentDetail.content.length}
              </span>
            </Grid>
            <Grid item>
              <span style={{ fontSize: '16px', color: '#464646' }}>
                Total Conversations
              </span>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <TopicDetailInformationItem
            label='Total Positive'
            value={<>{data.positive}</>}
          />
          {/* <TopicDetailInformationItem
            label='Total Neutral'
            value={<>{data.neutral}</>}
          /> */}
          <TopicDetailInformationItem
            label='Total Negative'
            value={<>{data.negative}</>}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TopicDetailInformation;
