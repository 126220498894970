import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import usePersonalData from '@/Lib/Hooks/usePersonalData';

import { useNavigate } from 'react-router-dom';
import { Button, ClickAwayListener, styled, Typography } from '@mui/material';
import { useState, useContext } from 'react';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { ExpandMore, Logout, Settings, Person } from '@mui/icons-material';

const ImageProfile = styled('img')({
  height: '2rem',
  width: '2rem',
  borderRadius: '50%',
  objectFit: 'cover',
});

const LIST_MENU = [
  {
    label: 'Settings',
    icon: <Settings />,
    path: '/setting/profile',
    id: 'settings',
  },
  {
    label: 'Sign Out',
    icon: <Logout />,
    id: 'sign-out',
  },
];

const ProfilePopper: React.FC = () => {
  const authentication = useContext(AuthContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { image, user } = usePersonalData(authentication);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleLogout = () => {
    authentication.func.changeAuthToFalse();
    navigate('/');
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'profile-popper' : undefined;

  return (
    <div>
      <Button
        startIcon={
          image ? <ImageProfile src={image} alt='Image Profile' /> : <Person />
        }
        endIcon={<ExpandMore />}
        aria-describedby={id}
        type='button'
        onClick={handleClick}
        color='primary'
        sx={{ color: '#000', fontFamily: 'Poppins' }}
        id='profile-toggle'
      >
        {user?.name}
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement='bottom-end'
        sx={{ zIndex: 11 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <List
                sx={{
                  p: 1,
                  background: 'white',
                  boxShadow: 3,
                  mt: 1,
                  minWidth: 200,
                }}
              >
                <Typography my={1} mx={2.5} sx={{ fontFamily: 'Poppins' }}>
                  My Account
                </Typography>
                {LIST_MENU.map((menu) => (
                  <ListItem disablePadding key={menu.label}>
                    <ListItemButton
                      onClick={() => {
                        if (menu.path) {
                          navigate(menu.path);
                        } else {
                          handleLogout();
                        }
                        setOpen(false);
                      }}
                      id={menu.id}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        {menu.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={menu.label}
                        sx={{ fontFamily: 'Poppins' }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default ProfilePopper;
