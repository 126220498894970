import axios from 'axios';

import type { AxiosError } from 'axios';

interface ErrorType {
  detail?: string;
  message?: string;
}

interface ResolvePromiseClient {
  code: number;
  detail: string;
}

export const resolvePromise = async <T>(
  promise: Promise<{
    data: T;
  }>
): Promise<readonly [T, null] | readonly [null, ResolvePromiseClient]> => {
  try {
    const { data } = await promise;
    return [data, null] as const;
  } catch (error) {
    if (error instanceof Error) {
      return [
        null,
        resolveErrorBackEnd(error as AxiosError<ErrorType>),
      ] as const;
    } else if (axios.isCancel(error)) {
      return [
        null,
        {
          code: 500,
          detail: 'Upload Canceled',
        },
      ] as const;
    } else {
      return [
        null,
        {
          code: 500,
          detail: 'Something went wrong',
        },
      ] as const;
    }
  }
};

export const resolveErrorBackEnd = (
  error: AxiosError<ErrorType>
): ResolvePromiseClient => {
  if (error.response) {
    const { response } = error;

    return {
      code: response.status,
      detail:
        response.data.detail ?? response.data.message ?? response.statusText,
    };
  } else if (error.request) {
    return {
      code: 500,
      detail: 'The request was made but no response was received',
    };
  } else {
    return {
      code: 500,
      detail: 'Something happened in setting up the request',
    };
  }
};
