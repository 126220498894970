import { Box, Grid } from '@mui/material';
import { MoonLoader } from 'react-spinners';
import { CardContainer } from '../CallMonitorStyle';
import { PrimaryColor } from '../../../Components/Style/Style';

interface StatisticCardContent {
  label: string;
  content: string;
  color: string;
}

export interface StatisticCardProps {
  type: 'conversation' | 'transcription' | 'duration';
  title: string;
  number: number | string;
  content: StatisticCardContent[];
}

const StatisticCard = ({
  type,
  title,
  number,
  content,
}: StatisticCardProps) => {
  return (
    <CardContainer>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        bgcolor='#F2FCFF'
        height='92px'
        borderRadius='15px 15px 0 0'
      >
        <Box
          fontFamily='Montserrat'
          fontSize={18}
          fontWeight={700}
          textAlign='center'
          color='#065DCC'
        >
          {title}
          {type === 'duration' ? (
            ''
          ) : (
            <Box mt={0.75}>
              {number === -999 ? (
                <MoonLoader size={20} color='white' />
              ) : (
                <Box>{number}</Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box p={2}>
        {content.map((data, index) => {
          return (
            <Grid
              container
              direction='row'
              key={index}
              justifyContent='space-between'
              fontFamily='Poppins'
              mb={2}
            >
              <Grid item sx={{ display: 'flex' }}>
                <Box
                  width={10}
                  height={10}
                  borderRadius='100%'
                  bgcolor={data.color}
                  mr={1}
                  sx={{ marginTop: '5px' }}
                />
                <Box>{data.label}</Box>
              </Grid>
              <Grid item fontWeight={700} color={PrimaryColor}>
                {number === -999 ? (
                  <MoonLoader size={10} color='white' />
                ) : (
                  data.content
                )}
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </CardContainer>
  );
};

export default StatisticCard;
