import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// import { PrimaryColor } from '../Style/Style';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableTable: {
      borderSpacing: 0,
      overflow: 'auto',
    },
    tableHeader: {
      backgroundColor: '#F0F2F4',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },
    },
    tableRow: {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },
    },
    tableRowSelected: {
      backgroundColor: '#9abcea',
      borderTop: 'none',
      borderBottom: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
      },
    },
    tableCell: {
      border: 'none',
    },
    formControl: {
      marginRight: theme.spacing(1),
      minWidth: 120,
      backgroundColor: '#F0F2F4',
      border: '1px solid #E4E9F0',
      borderRadius: '8px',
      color: '#444444',
      '.MuiSelect-filled:focus': {
        backgroundColor: '#F0F2F4',
      },
    },
    icon: {
      fill: '#333333',
    },
  })
);
