import dayjs from 'dayjs';
import { RangeType } from './../react-app-env.d';
import { UserData } from '@/Components/Authentication/Authentication';

const LOCAL_AUTH_KEY = 'AUTH';
export const CONVERSATION_TABLE_KEY = 'tableState:Conversation';
export const AGENT_STATISTICS_TABLE_KEY = 'tableState:AgentStatistics';
export const USER_TABLE_KEY = 'tableState:User';
export const ROLE_RIGHTS_TABLE_KEY = 'tableState:RoleRights';
export const CUSTOMER_TABLE_KEY = 'tableState:Customer';
export const AGENT_TABLE_KEY = 'tableState:Agent';
export const AUDIT_TABLE_KEY = 'tableState:Audit';
export const AGENT_HISTORY__TABLE_KEY = 'tableState:ConversationAgentHistory';
export const DATE_KEY = 'dateSession';

export const saveCredential = (authentication: UserData): void => {
  localStorage.setItem(LOCAL_AUTH_KEY, JSON.stringify(authentication));
};

export const getCredential = (): UserData => {
  const authJSON = localStorage.getItem(LOCAL_AUTH_KEY);
  if (!authJSON)
    return {
      isAuth: false,
      data: {
        id: -1,
        username: '',
        token: '',
        permissions: [],
        image: '',
      },
    };
  const auth = JSON.parse(authJSON);
  return auth;
};

export const clearCredential = (): void => {
  localStorage.removeItem(LOCAL_AUTH_KEY);
};

export const getPageSizeConversation = (KEY: string): number => {
  const item = window.localStorage.getItem(KEY);
  const session = item ? JSON.parse(item) : '';
  if (session === null) {
    return 10;
  }
  if (session === undefined) {
    return 10;
  }
  if (session.pageSize === null) {
    return 10;
  }
  return session.pageSize;
};

export const saveDate = (range: RangeType) => {
  localStorage.setItem(DATE_KEY, JSON.stringify(range));
};

export const getDate = (): RangeType => {
  const range = localStorage.getItem(DATE_KEY);
  if (range) {
    return JSON.parse(range);
  }
  saveDate({
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  });
  return {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
  };
};

export const clearDate = () => {
  localStorage.removeItem(DATE_KEY);
};
