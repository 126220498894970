import { useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';

import type { OutlinedInputProps } from '@mui/material';

interface PasswordInputFieldProps {
  outlinedInputProps: OutlinedInputProps;
  touched?: boolean;
  helperText?: string;
}

const PasswordInputField: React.FC<PasswordInputFieldProps> = ({
  outlinedInputProps,
  touched,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl size='small' fullWidth error={touched && Boolean(helperText)}>
      <InputLabel
        htmlFor={outlinedInputProps.id}
        sx={{ fontFamily: 'Poppins' }}
      >
        {outlinedInputProps.label}
      </InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword((c) => !c)}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        inputProps={{
          style: { fontFamily: 'Poppins' },
        }}
        autoComplete='current-password'
        {...outlinedInputProps}
      />

      {
        <FormHelperText error={touched && Boolean(helperText)}>
          {touched ? (helperText ? helperText : ' ') : ' '}
        </FormHelperText>
      }
    </FormControl>
  );
};

export default PasswordInputField;
