import ClipLoader from 'react-spinners/ClipLoader';
import { PrimaryColor } from '../Style/Style';
import { Box } from '@mui/material';

const Loading = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >
      <ClipLoader size={60} color={PrimaryColor} />
    </Box>
  );
};

export default Loading;
