import { useContext, useState, useEffect } from 'react';
import { RangeState } from './ConversationMonitor';
import { ChartContainer } from '../CallMonitorStyle';
import { AuthContext } from '@/Components/Authentication/Authentication';
import { convertHMS } from '../../../Utility/utils';
import { toastConfig } from '../../../Components/Style/Style';
import { toast } from 'react-toastify';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
} from 'recharts';
import {
  ConversationsDurationDetailInterface,
  getConversationsDurationDetail,
} from '../../../Model/CallMonitor';
import { PrimaryColor } from '../../../Components/Style/Style';

interface ChartDurationProps {
  range: RangeState;
}

interface CustomAxisTickProps {
  x?: number;
  y?: number;
  payload?: any;
}

interface CustomTooltipProps {
  payload?: any;
  label?: string;
  active?: boolean;
}

const CustomAxisTick = ({ x, y, payload }: CustomAxisTickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor='start'
        transform='rotate(90)'
        fontFamily='Poppins'
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ payload, label, active }: CustomTooltipProps) => {
  if (active) {
    return (
      <div
        style={{
          borderRadius: '10px',
          background: 'white',
          border: '1px solid rgba(0, 55, 112, 0.24)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px 10px 0 0',
            background: '#f2fcff',
            color: '#065dcc',
            padding: '0.25rem',
            fontFamily: 'Poppins',
          }}
        >
          <h4>{label}</h4>
        </div>
        <div style={{ padding: '0 1rem' }}>
          {payload?.map((item: any, index: any) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: '200px',
                margin: '8px 0',
                fontFamily: 'Poppins',
              }}
              key={`custom-tooltip-chartduration-${index}`}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '7px',
                    height: '7px',
                    background: item.stroke,
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                {item.name}
              </div>
              <h4 style={{ color: PrimaryColor, fontWeight: 700 }}>
                {item.dataKey === 'count'
                  ? item.value
                  : convertHMS(Math.floor(item.value))}
              </h4>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const ChartDuration = ({ range }: ChartDurationProps) => {
  const authentication = useContext(AuthContext);
  const [data, setData] = useState<ConversationsDurationDetailInterface[]>([]);

  useEffect(() => {
    const fetchConversationDurationStatistics = async () => {
      const result = await getConversationsDurationDetail(
        authentication.auth.data.token,
        range.startDate,
        range.endDate
      );
      if (result === false) {
        authentication.func.changeAuthToFalse();
      } else if (typeof result === 'string') {
        toast.dark(result, toastConfig);
      } else {
        setData(result);
      }
    };
    fetchConversationDurationStatistics();
  }, [range, authentication]);

  return (
    <ChartContainer>
      <div style={{ padding: '0 1.5rem 1.5rem' }}>
        <div>
          <h3
            style={{
              marginTop: 0,
              fontFamily: 'Montserrat',
              color: PrimaryColor,
            }}
          >
            Calls Duration Trends
          </h3>
        </div>
      </div>

      <div style={{ height: '390px', marginLeft: '-2rem' }}>
        <ResponsiveContainer width='99%' height='100%'>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 32,
              left: 28,
              bottom: 16,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='name'
              height={120}
              style={{ fontFamily: 'Poppins' }}
              interval={0}
              tick={<CustomAxisTick />}
            />
            <YAxis style={{ fontFamily: 'Poppins' }} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type='monotone'
              dataKey='count'
              name='Count'
              stroke='#444444'
              strokeWidth={0}
              dot={false}
              activeDot={false}
            />
            <Line
              type='monotone'
              dataKey='total'
              name='Total'
              stroke='#3569B7'
              dot={false}
            />
            <Line
              type='monotone'
              dataKey='longest'
              name='Longest'
              stroke='#42CAF4'
              dot={false}
            />
            <Line
              type='monotone'
              dataKey='average'
              name='Average'
              stroke='#6A39D2'
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </ChartContainer>
  );
};

export default ChartDuration;
