import { Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ minHeight: '100vh', paddingLeft: '85px', paddingRight: '10px' }}
    >
      <Grid item>
        <h2 style={{ textAlign: 'center' }}>404 - PAGE NOT FOUND</h2>
      </Grid>
      <Grid item>
        <p style={{ maxWidth: '400px', textAlign: 'center' }}>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
      </Grid>
      <Grid item sx={{ marginTop: 3 }}>
        <NavLink to='/'>
          <Button variant='contained' color='primary'>
            Go To Homepage
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default NotFound;
